export default {
    leftmenu: {
        new:'New Chat',
        search:'Search Chat...',
        talkhistroy:'对话历史',
        language:'修改中英文',
        setting:'Setting',
        recycle:'Recycle',
        light:'亮色',
        dark:'暗色',
    },
    midmenu:{
        remarkfont:'释放AI的力量',
        topfont:'New Chat',

        chechklist1:'Chat Record Search',
        chechklist2:'Prompt Library',
        chechklist3:'Integration',
        chechklist4:'Run Locally on Browser',
        chechklist5:'No Login Required',
        chechklist6:'Use Your Own API Key',
        chechklist7:'No Monthly Fee',
        chechklist8:'And More...',
    

        premium:'View Premium Features',
        remindone:'To get started, please enter your OpenAI API key below',
        remindtwo:'Your API key is stored locally in your browser and will never be sent anywhere else',
    
        interfencebtn:'Enter API Key',
        remindthird:'Get your API key from the OpenAI dashboard',
        model:'Model: GPT-3.5',
        role:'Select Role',
        remindlib:'Prompt Library',
        modelremindone:'Model: GPT-4',
        modelremindtwo:'System Prompt: You are ChatGPT, a large language model trained by OpenAI.',
        inputcontent:'Your message here...',
    }
}