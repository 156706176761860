export const parseVideoInfo = (res) => {
    const data = res.data;
    if(!res.data) return {}
    /***
     *  "bookId": 1294,
        "categoryId": 38,
        "bookName": "发音训练_1",
        "desc": "发音训练_1",
        "cover": "https://cckid-user-content.cckidabc.com/WOW/01Yellow/video/001.Wow!Yellow%20Unit%201%20hello%20video_story.mp4?vframe/jpg/offset/14",
        "videoUrl": "https://cckid-user-content.cckidabc.com/WOW/01Yellow/video/001.Wow!Yellow%20Unit%201%20hello%20video_story.mp4",
        "audioUrl": "https://cckid-user-content.cckidabc.com/WOW/01Yellow/audio/001.Wow!Yellow%20Unit%201%20hello%20video_story.mp3",
        "subtitleUrl": "https://cckid-user-content.cckidabc.com/WOW/01Yellow/video/001.Wow!Yellow%20Unit%201%20hello%20video_story.srt",
        "tag": "0510",
        "sort": 1,
        "execises": null,
        "execisesCouseType": "pdf"
     */
    return {
        ...res,
        data: {
            "id": data.bookId,
            "video_name": data.bookName,
            "star": 1,
            "video_img": data.cover,
            "video_url": data.videoUrl,
            "cn_captions": data.subtitleUrl,
            "en_captions": data.enSubtitleUrl,
            "is_like": 0,
            "xuebi_url": data.cover
        }
    };
}