<template>
  <div class="layout">
    <div class="left">
      <Leftmenu @getDark="getDark" :ismenu="ismenu" @changeismenu="changeismenu" />
    </div>
    <div class="center">
      <img class="humburger-btn" v-if="!ismenu" src="../assets/nomore.svg" @click="changeismenu(true)">
      <router-view :isdark="isdark" :ismenu="ismenu" @changeismenu="changeismenu"></router-view>
    </div>
  </div>
</template>

<script>
import Leftmenu from '../components/LeftMenu2.vue';
export default {
  name: 'main-layout',
  components: { Leftmenu },
  watch: {
    screenWidth: {
      handler: function (val) {
        this.ismenu = val >= 1300
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      screenWidth: document.documentElement.clientWidth,
      isphone: false, //是否是手机
      isdark: false,//是否暗色主题
      ismenu: false,
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    // var local = localStorage.getItem("lang") == "zh"?"zh":"en"
    var local = "zh"
    localStorage.setItem("lang", local)
    this.$i18n.locale = local
    // 初始化主题色
    window.document.documentElement.setAttribute("data-theme", "dark")
    if (this._isMobile()) {
      this.isphone = true
    } else {
      this.isphone = false
    }
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.screenWidth = window.fullWidth; // 宽
      })()
    };
  },
  methods: {
    changeismenu(flag) {
      this.ismenu = flag
      console.log(this.ismenu)
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getDark(isdark) {
      this.isdark = isdark
    },
  }
}  
</script>

<style>
/* layout styles */
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: #1C1D22;
}

.center {
  flex: 1;
  padding: 20px 10px;
  overflow: hidden;
}

.humburger-btn {
  position: absolute;
  left: 20px;
  top: 42px;
  width: 18px;
  height: 16px;
  cursor: pointer;
  z-index: 99;
}
</style>
