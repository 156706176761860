<template>
  <template v-if="subtitlesType !== 4">
    <div v-if="!props.showAll" class="subtitles-wrapper" :style="`line-break: anywhere;${styleStr}`">
      <div class="primary-subtitle" v-if="subtitlesType !== 3" :key="HiddenList.join(',') + CollectedList.join(',')">
        <WordGroup :list="curSubtitle?.wordList" :highList="CollectedList">
          <template v-slot="{ word }">
            <PopoverWord :word="word" @changeVisible="changeVisible" :isCollected="CollectedList.includes(word)"
              :isAllMaster="HiddenList.includes(word)" :key="word" @onAllMasterWord="handleHideWord"
              @onCollectWord="(word) => handleCollectWord(word, curSubtitle)" />
          </template>
        </WordGroup>
        <i :class="`collect-btn iconfont ${curSubtitle?.is_like === 1 ? 'icon-xihuan-' : 'icon-xihuan'
          }`" @click="handleCollect(curSubtitle)"></i>
      </div>
      <p v-if="subtitlesType !== 2" class="secondary-subtitle">
        {{ curSubtitle?.secondary }}
      </p>
    </div>
    <ul class="subtitles-list" v-else>
      <template v-for="(item, idx) in subtitleList">
        <li :key="HiddenList.join(',') + CollectedList.join(',')" v-if="item.startTime > 0"
          :class="`item ${playingLineIdx === idx ? 'active' : ''}`" :style="styleStr">
          <div style="display: flex; gap: 10px">
            <i className="play-btn iconfont icon-bofang1" @click="handleClickItem(item)"></i>
            <div class="primary-subtitle" v-if="subtitlesType !== 3">
              <WordGroup :list="item.wordList" :highList="CollectedList">
                <template v-slot="{ word }">
                  <PopoverWord :word="word" :key="word" @changeVisible="changeVisible"
                    :isCollected="CollectedList.includes(word)" :isAllMaster="HiddenList.includes(word)"
                    @onAllMasterWord="handleHideWord" @onCollectWord="(word) => handleCollectWord(word, item)" />
                </template>
              </WordGroup>
            </div>
            <i :class="`collect-btn iconfont ${item.is_like === 1 ? 'icon-xihuan-' : 'icon-xihuan'
              }`" @click="handleCollect(item, idx)"></i>
          </div>
          <p v-if="subtitlesType !== 2" class="secondary-subtitle">
            {{ item.secondary }}
          </p>
        </li>
      </template>
    </ul>
  </template>
  <p class="subtitle-closed" v-else>
    <i class="iconfont icon-danmu-" style="font-size: 22px"></i>字幕关闭
  </p>
  <Teleport v-if="linkVisible" to="body">
    <div class="link-word-dialog" v-click-outside="() => (linkVisible = false)">
      <p class="title">选择要链接的单词</p>

      <div class="content">
        <p v-if="!uniqWordList.length">暂无数据</p>
        <ul class="word-list">
          <li :class="`${item.selected ? 'selected' : ''}`" v-for="item in uniqWordList" :key="item.label"
            @click="selectWord(item)">
            {{ item.label }}
          </li>
        </ul>
        <el-checkbox v-model="isLinkAll" style="margin-bottom: 10px" @change="onSelectAll"
          v-if="uniqWordList.length > 1">链接所有单词</el-checkbox>
        <ul v-for="item in uniqWordList" v-show="item.selected">
          <p>{{ item.label }}</p>
          <el-checkbox v-for="(explain, idx) in item.explains" :key="explain.value"
            @change="(checked) => handleCheckedExplain(checked, item, idx)" :value="explain.value">
            {{ explain.label }}
          </el-checkbox>
        </ul>
      </div>
      <div class="footer">
        <button @click="linkVisible = false">取消</button>
        <button @click="onConfirm">确认</button>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, watch, ref, defineProps } from "vue";
import { useStore } from "vuex";
import PopoverWord from "@/components/PopoverWord/index.vue";
import WordGroup from "@/components/WordGroup/index.vue";
import { ElMessage } from "@/components/MyMessage";
import compact from "lodash/compact";
import { ClickOutside as vClickOutside } from "element-plus";
import api from "@/api/video";
import emitter from "@/utils/eventBus";
import { useRouter } from "vue-router";
import { Events } from "xgplayer";

const props = defineProps({
  showAll: Boolean,
});

const router = useRouter();
const store = useStore();
const player = computed(() => store.state.videoPlayer.player);
const subtitleList = ref(store.state.subtitles.list);
const subtitlesType = computed(() => store.state.subtitles.subtitleType);
const currentTime = computed(() => store.state.videoPlayer.currentTime);
const styleStr = ref(store.getters["subtitles/styleStr"]);
const linkVisible = ref(false);
const video_id = router.currentRoute.value.query.video_id;
const currentVideoInfo = computed(
  () => store.state.videoPlayer.currentVideoInfo
);
const curSubtitle = computed(() => {
  const res = subtitleList.value.filter((item) => {
    if (currentTime.value >= item.startTime) return item;
  });
  return res[res.length - 1];
});
const uniqWordList = ref([]);
const isLinkAll = ref(false);
const sentenceCheckedList = ref([]);
const CollectedList = ref(
  localStorage.getItem("CollectedList")?.split(",") ?? []
);
const HiddenList = ref(localStorage.getItem("HiddenList")?.split(",") ?? []);
watch(
  () => store.state.subtitles.list,
  (newValue) => {
    subtitleList.value = newValue;
  }
);

watch(
  () => store.getters["subtitles/styleStr"],
  (newStyle) => {
    styleStr.value = newStyle;
  }
);

watch(
  () => subtitleList.value,
  (val) => {
    const wordList = [...new Set(val.map((item) => item.wordList).flat(1))];
    getHeightLightWord(wordList);
    getHideWord(wordList);
  }
);

// 句子收藏
const handleCollect = async (item, idx) => {
  const { is_like, id, primary, secondary, startTime, endTime } = item;
  if (is_like !== 1) {
    changeVisible("", item);
  } else {
    canCelCollectSentence(item);
  }
};
// 取消收藏
const canCelCollectSentence = (sentence) => collectSentence(sentence, [], 1);
// 收藏例句
const collectSentence = async (sentence, explain_id = [], isLike = null) => {
  const { is_like, id, primary, secondary, startTime, endTime } = sentence;
  isLike = isLike == null ? is_like : isLike; // 单词模式只会触发收藏
  let params = {
    sentence_id: id,
    type: isLike === 1 ? 2 : 1,
    sentence: primary,
  };
  if (explain_id.length) {
    params["explain_id"] = explain_id;
  }
  if (id === 0) {
    Object.assign(params, {
      translate_sentence: secondary || "",
      video_id: Number(video_id),
      start_time: String(startTime),
      end_time: String(endTime),
      sentence_url: `${currentVideoInfo.value.video_url}?vframe/jpg/offset/${startTime}`,
    });
  }
  const { code, data } = await api.collectSentence(params);
  if (code === 0) {
    store.commit("subtitles/updateSentenceLikeById", id);
    ElMessage({
      message: isLike === 1 ? "例句已取消收藏" : "例句收藏成功",
      type: "success",
    });
    Object.assign(sentence, {
      is_like: isLike === 1 ? 2 : 1,
      id: typeof data === "number" ? data : id,
    });
    store.commit("subtitles/updateList", subtitleList.value);
  }
};

// 链接单词
const changeVisible = (word = "", sentence = curSubtitle.value) => {
  const list = sentence.wordList.filter((item, index, self) =>
    /^[a-zA-Z]+$/.test(item)
  );
  api.getExplainByWordId({ word: list }).then((res) => {
    let result = Object.keys(res.data).map((item) =>
      res.data[item]
        ? {
          label: item,
          selected: word && word === item,
          explains: res.data[item].explainlist.map((item) => ({
            label: item.content,
            value: item.id,
            selected: false,
          })),
        }
        : null
    );
    uniqWordList.value = compact(result);
    isLinkAll.value = false;
    linkVisible.value = true;
  });
};

const onSelectAll = () => {
  uniqWordList.value.map((item) => (item.selected = true));
};

const selectWord = (item) => {
  item.selected = !item.selected;
};

const handleCheckedExplain = (checked, item, idx) => {
  item.explains[idx].selected = checked;
  if (checked) {
    sentenceCheckedList.value.push(item.explains[idx].value);
  } else {
    sentenceCheckedList.value = sentenceCheckedList.value.filter(
      (item) => item != item.explains[idx].value
    );
  }
  console.log("%c Line:98 🍿 item, idx", "color:#4fff4B", item, idx);
};

// TODO 接口、数据过滤
const onConfirm = () => {
  if (!sentenceCheckedList.value.length) {
    return ElMessage({
      message: "因为未选中任何解释,所以无法绑定，请先选中解释",
      type: "warning",
    });
  }
  collectSentence(curSubtitle.value, sentenceCheckedList.value).then(() => {
    linkVisible.value = false;
  });
};

// 当前播放的句子
const playingLineIdx = ref(null);
const updateCurrentSubTitle = (time) => {
  const currentPlayingIndex = computed(() => {
    for (let i = 0; i < subtitleList.value.length; i++) {
      if (
        time >= subtitleList.value[i].startTime &&
        (i === subtitleList.value.length - 1 ||
          time < subtitleList.value[i + 1].startTime)
      ) {
        return i;
      }
    }
    return null; // 如果没有匹配项，则返回null或其他适当的值
  });
  playingLineIdx.value = currentPlayingIndex.value;

  store.commit("videoPlayer/changeCurrentTime", time);
  store.commit(
    "subtitles/updateCurSubTitle",
    subtitleList.value[currentPlayingIndex.value]
  );
};
emitter.on(Events.TIME_UPDATE, updateCurrentSubTitle);

const handleClickItem = (item) => {
  player.value.currentTime = item.startTime;
  updateCurrentSubTitle(item.startTime);
};

const getHeightLightWord = async (val) => {
  const res = await api.getHeightLightWord(val);
  if (res.data) {
    CollectedList.value = res.data || [];
    localStorage.setItem("CollectedList", CollectedList.value.join(","));
  }
};

const getHideWord = async (val) => {
  const res = await api.getHideWord(val);
  if (res.data) {
    HiddenList.value = res.data || [];
    localStorage.setItem("HiddenList", HiddenList.value.join(","));
  }
};

const handleHideWord = (word) => {
  if (HiddenList.value.includes(word)) {
    HiddenList.value = HiddenList.value.filter((item) => item != word);
  } else {
    HiddenList.value.push(word);
  }
  localStorage.setItem("HiddenList", HiddenList.value.join(","));
};

const handleCollectWord = (word, sentence) => {
  let isLike = CollectedList.value.includes(word);
  if (isLike) {
    CollectedList.value = CollectedList.value.filter((item) => item != word);
  } else {
    CollectedList.value.push(word);
  }
  localStorage.setItem("CollectedList", CollectedList.value.join(","));
};
</script>

<style lang="scss">
.subtitles-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1045px;
  width: 80%;
  height: 108px;
  margin-top: 20px;
  border-radius: 16px;
  margin-bottom: 40px;
}

.primary-subtitle {
  position: relative;
  color: var(--fff);
  line-height: 33px;
  line-break: anywhere;

  .iconfont {
    font-size: 24px;
    color: #999;
    cursor: pointer;
    opacity: 0;
  }

  .collect-btn {
    position: absolute;
    top: 0;
    right: -30px;

    &.icon-xihuan- {
      color: #ef4242;
    }
  }

  &:hover {
    .collect-btn {
      opacity: 1;
    }
  }
}

.subtitles-list {
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    cursor: pointer;

    [data-platform="mobile"] & {
      font-size: 16px !important;
      margin-bottom: 12px;
    }

    .iconfont {
      font-size: 24px;
      line-height: 33px;
      color: #999;
      opacity: 0;
    }

    .collect-btn.icon-xihuan- {
      color: #ef4242;
      opacity: 1;
    }

    &:hover {

      .collect-btn,
      .play-btn {
        opacity: 1;
      }
    }

    &.active .primary-subtitle {
      color: #fbd026;
    }

    .secondary-subtitle {
      padding-left: 34px;
    }
  }
}

.secondary-subtitle {
  color: #999;
}

.subtitle-closed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 196px;
  margin-top: 20px;
  color: #999;

  .iconfont {
    margin-right: 6px;
  }
}
</style>