<template>
  <!-- 左边菜单 -->

  <div v-if="ismenu" class="leftdiv">
    <!-- 左侧列表加上方按钮 -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
      "
    >
    <div style="display:flex">
      <img
        src="../assets/onechat.svg"
        alt=""
        style="width: 149px; height: 40px; margin-left: 10px"
      />
      <div style="    display: flex;
    align-items: center;">
        <SelectPanel></SelectPanel>
      </div>
    </div>
      
      <img
        src="../assets/nomore.svg"
        alt=""
        @click="changeismenu()"
        style="width: 18px; height: 16px; cursor: pointer"
      />
    </div>
    <!-- 左侧列表 -->
    <div
      style="
        display: flex;
        align-items: center;
        margin-top: 40px;
        justify-content: space-between;
      "
    >
      <div style="display: flex">
        <img
          src="../assets/chat.svg"
          alt=""
          style="width: 20px; height: 20px; margin-right: 9px"
        />
        <div style="color: white; font-weight: 500">
          {{ t("leftmenu.talkhistroy") }}
        </div>
      </div>
      <div>
        <img
          src="../assets/search_data.svg"
          alt=""
          style="width: 20px; height: 20px; margin-right: 15px; cursor: pointer"
          @click="opensearch()"
        />
        <img
          src="../assets/addplus.svg"
          alt=""
          style="width: 20px; height: 20px; margin-right: 0px; cursor: pointer"
          @click="openaddrbt()"
        />
      </div>
    </div>
    <div
      style="margin-top: 15px; overflow: auto; height: 80vh; padding: 10px 10px"
      class="chattotallist"
    >
      <div v-for="(item, index) in chat_list" :key="index">
        <div
          v-if="item.select"
          class="chat_listdiv"
          style="background-color: rgba(19, 128, 255, 1)"
        >
          <img
            :src="item.prompts.avatar"
            alt=""
            style="
              width: 40px;
              height: 40px;
              margin-left: 5px;
              margin-right: 10px;
              border-radius: 50%;
            "
          />
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; justify-content: space-between">
              <div
                style="
                  color: var(--fff);
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 3px;
                  width: 80px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
              >
                {{ item.prompts.title }}
              </div>
              <div style="color: var(--fff); font-size: 10px; margin-right: 10px">
                {{ item.date }}
              </div>
            </div>
            <div
              style="
                color: var(--fff);
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 185px;
              "
            >
              {{ item.prompts.desc }}
            </div>
          </div>
        </div>
        <div v-else class="chat_listdiv" @click="selectchat(item)">
          <img
            :src="item.prompts.avatar"
            alt=""
            style="
              width: 40px;
              height: 40px;
              margin-left: 5px;
              margin-right: 10px;
              border-radius: 50%;
            "
          />
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; justify-content: space-between">
              <div
                style="
                  color: var(--fff);
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 3px;
                  width: 80px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
              >
                {{ item.prompts.title }}
              </div>
              <div style="color: var(--fff); font-size: 10px; margin-right: 10px">
                {{ item.date }}
              </div>
            </div>
            <div
              style="
                color: var(--fff);
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 185px;
              "
            >
              {{ item.prompts.desc }}
            </div>
          </div>
        </div>

        <!-- <img id="deleteicon" src="../assets/chat_list_delete.svg" alt="" style="width:20px;height:20px;position:absolute;right: 10px;" @click.stop="deletechat(item)"> -->
      </div>
    </div>
    <el-button
      style="margin-top: 30px; border-radius: 12px"
      type="primary"
      size="large"
      v-if="!islogin"
      @click="gotologin"
      >登录</el-button
    >

    <div
      v-else
      style="background: var(--1f222a); border-radius: 12px; padding: 20px 12px"
    >
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; width: 100%">
          <img
            :src="userinfo.avatar"
            alt=""
            style="width: 48px; height: 48px"
          />
          <div
            style="
              display: flex;
              width: 100%;
              margin-left: 5px;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div style="font-size: 18px; color: var(--fff); font-weight: 600">
                {{ userinfo.phone }}
              </div>
              <img
                v-if="userinfo.free == 0"
                src="../assets/image/freeflag.svg"
                alt=""
                style="width: 48px; height: 20px"
              />
            </div>
            <div style="font-size: 12px; color: #686c77; margin-bottom: 4px">
              剩余 {{ userinfo.second }} 条消息，升级获得更多
            </div>
          </div>
        </div>
        <div style="display:flex;    align-items: center;">
          <div
          style="
            cursor: pointer;
            width: 100%;
            height: 40px;
            border: 1px solid #69728b;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            margin-top: 20px;
            color: var(--fff);
            align-items: center;
            justify-content: center;
          "
          @click="openpaydia()"
        >
          升级到高级版
        </div>
        <div>
          <button
            style="margin-top: 20px"
            class="bottomsettingnew"
            @click="opensetting()"
          >
            <img
              src="../assets/image/setting.svg"
              alt=""
              style="width: 17px; height: 17px; margin-right: 8px; margin-left: 15px"
            /> 
          </button>
        </div>
        </div>
       
      </div>
    </div>
    <!-- <button style="margin-top:20px;" class="bottomsetting" @click="changelanguage()"><img src="../assets/setting.svg" alt=""  style="width:17px;height:17px;margin-right: 8px;margin-left: 15px;">{{t('leftmenu.language')}}</button> -->
    <!-- <button
      style="margin-top: 20px"
      class="bottomsetting"
      @click="opensetting()"
    >
      <img
        src="../assets/image/setting.svg"
        alt=""
        style="width: 17px; height: 17px; margin-right: 8px; margin-left: 15px"
      />{{ t("leftmenu.setting") }}
    </button> -->

    <!-- <el-popover placement="top-start" :width="200" trigger="hover">
      <template #reference>
        <button style="margin-top: 20px" class="bottomsetting">
          <img
            src="../assets/image/qrcode.svg"
            alt=""
            style="
              width: 17px;
              height: 17px;
              margin-right: 8px;
              margin-left: 15px;
            "
          />下载app软件
        </button>
      </template>
      <template #default>
        <div style="display: flex; flex-direction: column; align-items: center">
          <img
            src="https://oss.onechatai.cn/avatar/avatar1.png"
            alt=""
            style="width: 150px; height: 150px"
          />
          <div style="margin-top: 15px; font-size: 12px">
            扫描二维码下载手机APP
          </div>
        </div>
      </template>
    </el-popover> -->

    <!-- 机器人列表弹框 -->
    <el-dialog
      v-model="searchshow"
      width="80%"
      align-center
      :show-close="false"
    >
      <div style="display: flex; justify-content: center">
        <div v-for="(item, index) in categorylist" :key="index">
          <div
            v-if="item.select"
            style="
              border: 1px solid #1380ff;
              padding: 5px 18px;
              background-color: var(--181a20);
              border-radius: 12px;
              margin: 3px 6px;
              color: white;
              cursor: pointer;
            "
          >
            {{ item.name }}
          </div>
          <div
            v-else
            style="
              padding: 5px 18px;
              background-color: var(--181a20);
              border-radius: 12px;
              cursor: pointer;
              margin: 3px 6px;
              color: white;
            "
            @click="selectcate(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 10px 5%;
          min-height: 800px;
          justify-content: space-around;
        "
      >
        <div v-for="(item, index) in cateforyinfolist" :key="index">
          <div
            style="
              width: 240px;
              height: 200px;
              position: relative;
              margin: 12px 15px;
              cursor: pointer;
            "
            @click="addrobortinfo(item)"
          >
            <div
              style="
                width: 100%;
                height: 64px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                background-color: rgb(24, 26, 32);
              "
            ></div>
            <img
              :src="item.avatar"
              alt=""
              style="
                width: 64px;
                height: 64px;
                border-radius: 8px;
                position: absolute;
                left: 50%;
                margin-left: -32px;
                top: 15px;
              "
            />
            <div
              style="
                width: 100%;
                height: 136px;
                display: flex;
                flex-direction: column;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                align-items: center;
                color: white;
                background-color: var(--181a20);
              "
            >
              <div style="font-size: 20px; font-weight: 600; margin-top: 23px">
                {{ item.title }}
              </div>
              <div
                style="
                  font-size: 14px;
                  margin: 8px 15px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  word-break: break-all;
                "
              >
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="cateforyinfolist.length % 4 == 3"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
        <div
          v-if="cateforyinfolist.length % 4 == 2"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
        <div
          v-if="cateforyinfolist.length % 4 == 2"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
        <div
          v-if="cateforyinfolist.length % 4 == 1"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
        <div
          v-if="cateforyinfolist.length % 4 == 1"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
        <div
          v-if="cateforyinfolist.length % 4 == 1"
          style="
            width: 240px;
            height: 200px;
            position: relative;
            margin: 12px 15px;
          "
        ></div>
      </div>
    </el-dialog>

    <!-- 设置列表的弹框 -->
    <el-dialog
      v-model="settingshow"
      class="settingclass"
      width="1000"
      align-center
      :show-close="false"
    >
      <div style="display: flex; justify-content: center; height: 600px">
        <div style="width: 252px">
          <div style="padding: 40px 20px">
            <div style="font-size: 24px; color: white">设置</div>
          </div>
          <div style="display: flex; flex-direction: column">
            <div
              class="settingmenu"
              :style="
                settingindex == 1
                  ? { background: 'var(--1f222a)' }
                  : { background: 'var(--181a20)' }
              "
              @click="selectsetting(1)"
            >
              <img
                src="../assets/image/setting1.svg"
                alt=""
                style="width: 16px; height: 16px"
              />
              <div style="margin-left: 12px; color: #ffffff; font-size: 16px">
                我的机器人
              </div>
            </div>
            <!-- <div class="settingmenu" :style="settingindex==2?{'background':'var(--1f222a)'}:{'background':'var(--181a20)'}" @click="selectsetting(2)">
                  <img src="../assets/image/setting2.svg" alt="" style="width:16px;height:16px">
                  <div style="margin-left:12px;color:#FFFFFF;font-size:16px">分享朋友</div>
                </div> -->
            <div
              class="settingmenu"
              :style="
                settingindex == 6
                  ? { background: 'var(--1f222a)' }
                  : { background: 'var(--181a20)' }
              "
              @click="selectsetting(6)"
            >
              <img
                src="../assets/image/setting6.svg"
                alt=""
                style="width: 16px; height: 16px; margin-top: 4px"
              />
              <div style="margin-left: 12px; color: #ffffff; font-size: 16px">
                第三方调用
              </div>
            </div>
            <div
              class="settingmenu"
              :style="
                settingindex == 3
                  ? { background: 'var(--1f222a)' }
                  : { background: 'var(--181a20)' }
              "
              @click="selectsetting(3)"
            >
              <img
                src="../assets/image/setting3.svg"
                alt=""
                style="width: 16px; height: 16px"
              />
              <div style="margin-left: 12px; color: #ffffff; font-size: 16px">
                加入微信交流群
              </div>
            </div>

            <div
              class="settingmenu"
              :style="
                settingindex == 4
                  ? { background: 'var(--1f222a)' }
                  : { background: 'var(--181a20)' }
              "
              @click="selectsetting(4)"
            >
              <img
                src="../assets/image/setting4.svg"
                alt=""
                style="width: 16px; height: 16px"
              />
              <div style="margin-left: 12px; color: #ffffff; font-size: 16px">
                关于 ChatNinja
              </div>
            </div>
            <div
              v-if="islogin"
              class="settingmenu"
              :style="
                settingindex == 5
                  ? { background: 'var(--1f222a)' }
                  : { background: 'var(--181a20)' }
              "
              @click="selectsetting(5)"
            >
              <img
                src="../assets/image/setting5.svg"
                alt=""
                style="width: 16px; height: 16px"
              />
              <div style="margin-left: 12px; color: #ffffff; font-size: 16px">
                退出登录
              </div>
            </div>
          </div>
        </div>
        <div style="width: 748px; background: var(--1f222a)">
          <div v-if="settingindex == 3" style="padding: 40px 34px">
            <div style="border-bottom: 1px solid #2e2e36; padding-bottom: 40px">
              <div style="font-size: 24px; color: white">加入微信交流群</div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                color: white;
              "
            >
              <div style="margin-top: 40px; font-size: 16px; font-weight: 600">
                对AI感兴趣，欢迎加入微信交流群
              </div>
              <div style="margin-top: 20px; font-size: 14px; font-weight: 600">
                微信号：sodapop999
              </div>
              <img
                src="../assets/image/wxinfo/wxinfo.png"
                alt=""
                style="width: 152px; height: 152px; margin-top: 34px"
              />
              <div style="margin-top: 15px; font-size: 12px">
                打开微信右上角扫一扫
              </div>
            </div>
          </div>
          <div v-if="settingindex == 4" style="padding: 40px 34px">
            <div style="border-bottom: 1px solid #2e2e36; padding-bottom: 40px">
              <div style="font-size: 24px; color: white">关于Chat Ninja</div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                color: white;
                margin-top: 10px;
              "
            >
              <div style="display: flex">
                <img
                  src="../assets/image/setting4.svg"
                  alt=""
                  style="width: 60px; height: 60px"
                />
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    width: 100%;
                    margin-left: 20px;
                  "
                >
                  <div style="font-size: 16px">Chat Ninja</div>
                  <div style="display: flex; justify-content: space-between">
                    <div style="font-size: 14px">v1.0.0</div>
                    <!-- <div style="font-size:14px">onechatai.cn xxxx公司 版权所有</div> -->
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  font-size: 16px;
                  margin-top: 50px;
                "
              >
                <div>反馈与咨询联系方式</div>
                <div>onechataicn@outlook.com</div>
              </div>
              <div
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  margin-top: 30px;
                "
                @click="jumpservicetext(1)"
              >
                服务条款
              </div>
              <div
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  margin-top: 30px;
                "
                @click="jumpservicetext(2)"
              >
                隐私协议
              </div>
              <el-button
                style="margin-top: 30px; width: 150px; border-radius: 20px"
                type="primary"
                size="large"
                >下载ChatNinja APP</el-button
              >
            </div>
          </div>
          <div v-if="settingindex == 1" style="padding: 40px 34px">
            <div style="border-bottom: 1px solid #2e2e36; padding-bottom: 40px">
              <div style="font-size: 24px; color: white">我的机器人列表</div>
            </div>
            <div style="height: 450px; overflow: auto">
              <div v-for="(item, index) in myrobortlist" :key="index">
                <div style="color: white; font-size: 20px; margin: 20px 0">
                  {{ item.mark }}
                </div>
                <div v-for="(obj, objindex) in item.dataList" :key="objindex">
                  <div
                    style="
                      width: 340px;
                      height: 80px;
                      color: white;
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                    "
                    @click="selectrobortlist(obj)"
                  >
                    <div style="display: flex">
                      <img
                        :src="obj.avatar"
                        alt=""
                        style="width: 48px; height: 48px; border-radius: 50%"
                      />
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-left: 20px;
                        "
                      >
                        <div style="font-size: 18px; font-weight: 600">
                          {{ obj.title }}
                        </div>
                        <div
                          style="
                            margin-top: 10px;
                            width: 250px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          {{ obj.desc }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="settingindex == 6" style="padding: 40px 34px">
            <div style="border-bottom: 1px solid #2e2e36; padding-bottom: 40px">
              <div style="font-size: 24px; color: white">嵌入到网站中</div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                color: white;
                margin-top: 10px;
              "
            >
              <div style="display: flex; font-size: 16px; margin-top: 40px">
                <div>将以下 代码 复制到支持自定义嵌入的网站中</div>
              </div>
              <div style="display: flex; width: 100%; margin-top: 40px">
                <el-input
                  v-model="iframeData"
                  placeholder="请登录后查看"
                  disabled
                  type="textarea"
                  :rows="5"
                  resize="none"
                ></el-input>
              </div>
              <el-button
                v-if="islogin"
                style="margin-top: 30px; width: 150px; border-radius: 20px"
                type="primary"
                size="large"
                @click="copyIframe"
                >一键复制</el-button
              >
              <el-button
                v-else
                style="margin-top: 30px; width: 150px; border-radius: 20px"
                type="primary"
                size="large"
                @click="gotologin"
                >登录</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>



    <!-- 绑定手机号 -->
    <el-dialog v-model="bindphoneshow" width="560" align-center :show-close="false">
      <div style="padding:62px 80px 67px 80px;width:360px;height:362px">
            <div style="font-size:32px;color:white">
              绑定手机号
            </div>
            <div  style="width:100%;margin-top:64px;">
              <el-input v-model="phone"  placeholder="请输入手机号" size="large"/>
              <div class="midshow" style="margin-top:26px;justify-content: space-between;"> 
                <el-input v-model="code"   placeholder="请输入验证码" size="large" style="width:230px;margin-right: 12px;"/>
                <el-button class="midshow buttonbtn verifycode" @click="getCaptcha" :disabled="codetext == '获取验证码' ? false : true" type="primary">
                  {{ codetext }}
                </el-button>
              </div>
              <div class="midshow buttonbtn loginbtn" @click="bindphoneinfo()"  :style="{'background-color':'var(--primary)','color':'#FFFFFF'}">
                绑定
              </div>
            </div>
        </div>
    </el-dialog>


    <!-- 新增机器人 -->
    <el-dialog
      v-model="addrobortshow"
      width="748"
      align-center
      :show-close="false"
    >
      <div style="display: flex; padding: 34px; flex-direction: column">
        <div style="display: flex">
          <el-upload
            class="upload-demo"
            action="/api/v1/chat/imageGetUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
          >
            <img
              v-if="rob_img == ''"
              src="../assets/image/robortimg.svg"
              alt=""
              style="width: 100px; height: 100px"
            />
            <img
              v-else
              :src="rob_img"
              alt=""
              style="width: 100px; height: 100px"
            />
          </el-upload>

          <div
            style="
              margin-left: 34px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            "
          >
            <div style="font-size: 16px; font-weight: 500; color: white">
              昵称<span style="color: red; margin-left: 5px">*</span>
            </div>
            <div style="margin-top: 9px">
              <el-input
                v-model.trim="rob_nickname"
                style="width: 490px"
                placeholder="最多输入20个字符"
                size="large"
                maxlength="20"
              />
            </div>
          </div>
        </div>
        <div style="margin-top: 28px">
          <div style="font-size: 16px; font-weight: 500; color: white">
            签名<span style="color: red; margin-left: 5px">*</span>
          </div>
          <div style="margin-top: 9px">
            <el-input
              v-model.trim="rob_sign"
              type="textarea"
              style="width: 628px"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="最多输入120个字符"
              size="large"
              maxlength="120"
            />
          </div>
        </div>
        <div style="margin-top: 28px">
          <div style="font-size: 16px; font-weight: 500; color: white">
            提示词<span style="color: red; margin-left: 5px">*</span>
          </div>
          <div style="margin-top: 9px">
            <el-input
              v-model.trim="rob_tishi"
              type="textarea"
              style="width: 628px"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="最多输入500个字符"
              size="large"
              maxlength="500"
            />
          </div>
        </div>
        <div
          style="
            margin-top: 28px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div></div>
          <el-button type="primary" size="large" @click="addrobortone()"
            >创建机器人</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 付费页面 -->
    <el-dialog v-model="payshow" width="1000" align-center :show-close="false">
      <div
        style="
          display: flex;
          padding: 40px 48px;
          flex-direction: column;
          color: var(--fff);
          align-items: center;
        "
      >
        <div style="font-size: 30px; font-weight: 600">
          升级到高级版享受更多
        </div>
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-top: 40px;
          "
        >
          <div
            v-for="(item, index) in paylist"
            :key="index"
            style="
              width: 340px;
              padding: 40px 32px;
              background: var(--181a20);
              display: flex;
              flex-direction: column;
              align-items: center;
              border-radius: 12px;
            "
          >
            <div style="font-size: 20px; font-weight: 500">Basic</div>
            <div style="margin-top: 24px; display: flex; align-items: center">
              <div style="font-size: 30px; font-weight: 700; margin-right: 5px">
                $9.99
              </div>
              <div style="color: #666666; font-size: 12px">/moth</div>
            </div>
            <div style="margin-top: 20px; display: flex; align-items: center">
              <div style="color: #666666; font-size: 12px; margin-right: 5px">
                大约
              </div>
              <div style="font-size: 24px; font-weight: 700">$0.33/天</div>
            </div>
            <el-button
              type="primary"
              style="
                border-radius: 30px;
                width: 100%;
                margin-top: 20px;
                height: 44px;
                font-size: 18px;
              "
              size="large"
              @click="opentopay(item)"
              >升级</el-button
            >
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 20px;
              "
            >
              <div
                style="display: flex; align-items: center; margin-top: 12px"
                v-for="(item, index) in basiclist"
                :key="index"
              >
                <img
                  src="../assets/image/paycheck.svg"
                  alt=""
                  style="margin-right: 15px"
                />
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 付费详情页面 -->
    <el-dialog
      v-model="payinfoshow"
      width="1000"
      align-center
      :show-close="false"
    >
      <div
        style="
          display: flex;
          padding: 40px 48px;
          flex-direction: column;
          color: var(--fff);
          align-items: center;
        "
      >
        <div style="font-size: 30px; font-weight: 600">
          升级到高级版享受更多
        </div>
        <div></div>
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-top: 40px;
          "
        >
          <div
            @click="changepay(1)"
            style="
              width: 260px;
              padding: 15px 0;
              background: var(--181a20);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              cursor: pointer;
            "
          >
            <img
              src="../assets/pay/wx.svg"
              alt=""
              style="width: 30px; height: 30px; margin-right: 8px"
            />
            <div style="font-size: 16px; font-weight: 500">微信支付</div>
          </div>
          <div
            @click="changepay(2)"
            style="
              width: 260px;
              padding: 15px 0;
              background: var(--181a20);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              cursor: pointer;
            "
          >
            <img
              src="../assets/pay/zfb.svg"
              alt=""
              style="width: 30px; height: 30px; margin-right: 8px"
            />
            <div style="font-size: 16px; font-weight: 500">支付宝支付</div>
          </div>
          <div
            @click="changepay(3)"
            style="
              width: 260px;
              padding: 15px 0;
              background: var(--181a20);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              cursor: pointer;
            "
          >
            <img
              src="../assets/pay/haiwai.svg"
              alt=""
              style="width: 30px; height: 30px; margin-right: 8px"
            />
            <div style="font-size: 16px; font-weight: 500">海外支付</div>
          </div>
        </div>
        <div
          style="
            margin-top: 24px;
            display: flex;
            align-items: center;
            margin-top: 25px;
          "
        >
          <div style="font-size: 30px; font-weight: 700; margin-right: 5px">
            $9.99
          </div>
          <div style="color: #666666; font-size: 12px">/moth</div>
        </div>
        <div
          style="
            width: 240px;
            height: 240px;
            background-color: var(--fff);
            margin-top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="wxcode"
            alt=""
            @error="wxcode = '../assets/image/wxinfo/scanma.svg'"
            style="width: 210px; height: 210px"
          />
        </div>
        <div style="margin-top: 16px; margin-bottom: 20px">
          使用手机微信扫码完成付款
        </div>

        <el-button
          type="primary"
          style="
            border-radius: 30px;
            width: 370px;
            margin-top: 20px;
            height: 44px;
            font-size: 18px;
          "
          size="large"
          >升级</el-button
        >
      </div>
    </el-dialog>

    <!-- 我的机器人 -->
    <el-dialog
      v-model="addmyrobortshow"
      width="748"
      align-center
      :show-close="false"
    >
      <div style="display: flex; padding: 34px; flex-direction: column">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
            "
          >
            <img
              :src="addrobortobj.avatar"
              alt=""
              style="width: 64px; height: 64px"
            />
            <div
              style="
                font-size: 20px;
                font-weight: 500;
                color: white;
                margin-left: 15px;
              "
            >
              {{ addrobortobj.title }}
            </div>
          </div>
          <el-button type="primary" size="large" @click="selectrobort()"
            >创建机器人</el-button
          >
        </div>
        <div style="font-size: 16px; color: #ffffff; margin-top: 25px">
          {{ addrobortobj.desc }}
        </div>
        <div style="margin-top: 54px">
          <div style="font-size: 16px; font-weight: 500; color: #1380ff">
            我是用以下提示创建的：
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="font-size: 16px; font-weight: 500; color: #7e7e7e">
            添加机器人后，您可以根据自己的喜好编辑提示！
          </div>
          <div style="margin-top: 30px">
            <el-input
              v-model.trim="addrobortobj.content"
              type="textarea"
              style="width: 100%"
              :autosize="{ minRows: 18, maxRows: 18 }"
              placeholder="最多输入120个字符"
              size="large"
              maxlength="120"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

 
<script>
 
import SelectPanel from "../components/SelectPanel/index.vue";

import { ElMessage } from "@/components/MyMessage";
import api from "../api/api";
import emitter from "../utils/eventBus";
import { useI18n } from "vue-i18n";
export default {
  emits: ["changeismenu"],
  components: {
    SelectPanel,
    
  },
  name: "HelloWorld",
  props: {
    ismenu: Boolean,
  },
  data() {
    return {
      tabchangeflag: false,

      huiliuflag: false,
      userinfo: {},
      basiclist: [
        "GPT-3.5消息：3000条/月",
        "GPT-4.0消息：40条/月",
        "PDF:50个文件/天，2000页/文件",
        "语音转文字和文字转语音",
        "无广告",
        "支持iPhone、iPad和Mac",
        "优先支持",
      ],
      Prolist: [
        "GPT-3.5消息：3000条/月",
        "GPT-4.0消息：40条/月",
        "PDF:50个文件/天，2000页/文件",
        "语音转文字和文字转语音",
        "无广告",
        "支持iPhone、iPad和Mac",
        "优先支持",
      ],
      order_time: {},
      pay_type: 1,
      goods_id: 0,
      wxcode: "",
      wxorder_no: "",
      paylist: [],
      payinfoshow: false,
      payshow: false,
      islogin: false, //是否登录
   
      addrobortobj: {
        obj: {},
        avatar: "",
        title: "",
        desc: "",
        content: "",
      },
      myrobortlist: [],
      rob_img: "",
      rob_nickname: "",
      rob_sign: "",
      rob_tishi: "",
      settingindex: 3,
      addmyrobortshow: false,
      addrobortshow: false,
      settingshow: false,
      cateforyinfolist: [],
      categorylist: [],
      searchshow: false,
      isdark: false,
      chat_list: [{prompts:{avatar:'https://oss.onechatai.cn/avatar/Frame7616101.png',title:'我的单词',desc:'通过浏览器插件收藏的词汇'}}],
      iframeData: "",
      bindphoneshow:false,
      fangdou:false,
      codetext:'获取验证码',
      code:"",
      phone:"",
    };
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  created() {
    var that = this;
    api.userinfo().then((res) => {
      if (res.code == 0) {
        that.userinfo = res.data;
        if (that.userinfo.avatar == "") {
          that.userinfo.avatar = that.userinfo.default_prompts_avatar;
        }
        const timestamp = Math.floor(new Date().getTime() / 1000);
        if (
          res.data.vip_expire_time > 0 &&
          res.data.vip_expire_time > timestamp
        ) {
          that.userinfo.second =
            that.userinfo.balance_gpt4 + that.userinfo.balance_gpt3;
          if (that.userinfo.second == 0) {
            ElMessage({
              message: "次数已用完",
              type: "error",
            });
          }
        } else {
          that.userinfo.second = that.userinfo.free;
          if (that.userinfo.second == 0) {
            ElMessage({
              message: "免费次数已用完",
              type: "error",
            });
          }
        }
        if (that.userinfo.token && that.userinfo.token != "") {
          that.iframeData =
            '<iframe\n   src="' +
            window.location.origin +
            "/chatninja/" +
            that.userinfo.token +
            '"\n   style="width: 100%; height: 100%;"\n   frameborder="0" >\n</iframe>';
        }
        that.userinfo.phone =
          that.userinfo.phone.slice(0, 3) +
          "****" +
          that.userinfo.phone.slice(8, 11);
        localStorage.setItem("phone", res.data.phone);
        localStorage.setItem(
          "default_avatar",
          that.userinfo.default_prompts_avatar
        );
        localStorage.setItem("avatar", that.userinfo.avatar);
        localStorage.setItem("nickname", res.data.nickname);
        localStorage.setItem("login_way", res.data.login_way);
        localStorage.setItem("free", res.data.free);
      } else {
        that.userinfo.avatar = localStorage.getItem("default_avatar");
        if (res.msg == "未登录,请先登录") {
          this.islogin = false;
          localStorage.setItem("vb-token", "");
        }
      }
      api.chathistory().then((res) => {
        if (res.code == 0) {
          this.chat_list = res.data.concat(this.chat_list);
          this.chat_list.forEach((element) => {
            element.select = false;
          });
          this.chat_list[0].select = true;
          this.selecttype(
            this.chat_list[0].prompts.class,
            this.chat_list[0].prompts
          );

          this.$forceUpdate();
        }
      });
    });

   

    emitter.on("getuserinfo", () => {
      that.getuserinfo();
      let x = 0
      let obj = {}
      for(let i = 0;i<that.chat_list.length;i++){
        if(that.chat_list[i].select){
          x = i
          obj = JSON.parse(JSON.stringify(that.chat_list[i]))
          obj.date = "刚刚"
        }
      }
      that.chat_list.splice(x,1)
      that.chat_list.unshift(obj)
    });
    if (
      localStorage.getItem("vb-token") &&
      localStorage.getItem("vb-token") != ""
    ) {
      this.islogin = true;
    } else {
      this.islogin = false;
    }

    // 初始化主题色
    this.isdark = true;
    localStorage.setItem("color", "dark");
    window.document.documentElement.setAttribute("data-theme", "dark");
    this.$emit("getDark", this.isdark);
  },

  methods: {
    getuserinfo() {
      var that = this;
      api.userinfo().then((res) => {
        if (res.code == 0) {
          that.userinfo = res.data;
          if (that.userinfo.avatar == "") {
            that.userinfo.avatar = that.userinfo.default_prompts_avatar;
          }
          const timestamp = Math.floor(new Date().getTime() / 1000);
          if (
            res.data.vip_expire_time > 0 &&
            res.data.vip_expire_time > timestamp
          ) {
            that.userinfo.second =
              that.userinfo.balance_gpt4 + that.userinfo.balance_gpt3;
            if (that.userinfo.second == 0) {
              ElMessage({
                message: "次数已用完",
                type: "error",
              });
            }
          } else {
            that.userinfo.second = that.userinfo.free;
            if (that.userinfo.second == 0) {
              ElMessage({
                message: "免费次数已用完",
                type: "error",
              });
            }
          }
          if (that.userinfo.token && that.userinfo.token != "") {
            that.iframeData =
              '<iframe\n   src="' +
              window.location.origin +
              "/chatninja/" +
              that.userinfo.token +
              '"\n   style="width: 100%; height: 100%;"\n   frameborder="0" >\n</iframe>';
          }
          that.userinfo.phone =
            that.userinfo.phone.slice(0, 3) +
            "****" +
            that.userinfo.phone.slice(8, 11);
          localStorage.setItem("phone", res.data.phone);
          localStorage.setItem("avatar", that.userinfo.avatar);
          localStorage.setItem("nickname", res.data.nickname);
          localStorage.setItem("login_way", res.data.login_way);
          localStorage.setItem("free", res.data.free);
        } else {
          that.userinfo.avatar = localStorage.getItem("default_avatar");
          if (res.msg == "未登录,请先登录") {
            this.islogin = false;
            localStorage.setItem("vb-token", "");
          }
        }
      });
    },
    changepay(index) {
      var that = this;
      this.pay_type = index;
      clearInterval(that.order_time);
      if (index == 1) {
        let data = {};
        data.goods_id = that.goods_id;
        data.payment_product = "native";
        api.wxpaycode(data).then((res) => {
          if (res.code == 0) {
            this.wxorder_no = res.data.order_no;
            this.wxcode = res.data.response;
            that.order_time = setInterval(() => {
              let checkdata = {};
              checkdata.order_no = res.data.order_no;
              api.checkorder(checkdata).then((res) => {
                if (res.code == 0) {
                  if (res.data.trade_state == "SUCCESS") {
                    ElMessage({
                      message: "支付成功",
                      type: "success",
                    });
                    clearInterval(that.order_time);
                    that.getuserinfo();
                    this.payinfoshow = false;
                    this.payshow = false;
                  }
                } else {
                  ElMessage({
                    message: "服务错误",
                    type: "error",
                  });
                }
              });
            }, 2000);
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else if (index == 3) {
        ElMessage({
          message: "支付进行中",
          type: "success",
        });
        let data = {};
        data.goods_id = that.goods_id;
        data.payment_product = "native";
        api.lemonpaycode(data).then((res) => {
          if (res.code == 0) {
            this.wxorder_no = res.data.order_no;
            window.open(res.data.response);
            that.order_time = setInterval(() => {
              let checkdata = {};
              checkdata.order_no = res.data.order_no;
              api.checkorder(checkdata).then((res) => {
                if (res.code == 0) {
                  if (res.data.trade_state == "SUCCESS") {
                    ElMessage({
                      message: "支付成功",
                      type: "success",
                    });
                    clearInterval(that.order_time);
                    that.getuserinfo();
                    this.payinfoshow = false;
                    this.payshow = false;
                  }
                } else {
                  ElMessage({
                    message: "服务错误",
                    type: "error",
                  });
                }
              });
            }, 2000);
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        let data = {};
        data.goods_id = that.goods_id;
        data.payment_product = "native";
        api.alipaycode(data).then((res) => {
          if (res.code == 0) {
            this.wxorder_no = res.data.order_no;
            window.open(res.data.response);
            that.order_time = setInterval(() => {
              let checkdata = {};
              checkdata.order_no = res.data.order_no;
              api.checkorder(checkdata).then((res) => {
                if (res.code == 0) {
                  if (res.data.trade_state == "SUCCESS") {
                    ElMessage({
                      message: "支付成功",
                      type: "success",
                    });
                    clearInterval(that.order_time);
                    this.payinfoshow = false;
                    this.payshow = false;
                  }
                } else {
                  ElMessage({
                    message: "服务错误",
                    type: "error",
                  });
                }
              });
            }, 2000);
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
    //打开具体支付页面
    opentopay(item) {
      var that = this;
      let data = {};
      data.goods_id = item.id;
      that.goods_id = item.id;
      data.payment_product = "native";
      api.wxpaycode(data).then((res) => {
        if (res.code == 0) {
          this.wxorder_no = res.data.order_no;
          this.wxcode = res.data.response;
          this.payinfoshow = true;
          that.order_time = setInterval(() => {
            let checkdata = {};
            checkdata.order_no = res.data.order_no;
            api.checkorder(checkdata).then((res) => {
              if (res.code == 0) {
                if (res.data.trade_state == "SUCCESS") {
                  ElMessage({
                    message: "支付成功",
                    type: "success",
                  });
                  clearInterval(that.order_time);
                  this.payinfoshow = false;
                  this.payshow = false;
                }
              } else {
                ElMessage({
                  message: "服务错误",
                  type: "error",
                });
              }
            });
          }, 2000);
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
          });
          if(res.msg == "未绑定手机请先绑定"){
            that.bindphoneshow = true
          }
        }
      });
    },
    //打开付费页面
    openpaydia() {
      this.payshow = true;
      api.getgoodsid().then((res) => {
        if (res.code == 0) {
          this.paylist = res.data;
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //上传机器人图片
    handleAvatarSuccess(res) {
      if (res.code == 0) {
        this.rob_img = res.data.url;
      } else {
        ElMessage({
          message: res.msg,
          type: "error",
        });
      }
    },
    opensetting() {
      this.settingshow = true;
    },
    openaddrbt() {
      this.addrobortshow = true;
      this.rob_nickname = "";
      this.rob_sign = "";
      this.rob_tishi = "";
      this.rob_img = "";
    },
    selecttype(index, data) {
      data.type = index;
      data.userinfo = this.userinfo;
      emitter.emit("selecTypechat", data);
    },
    selectsetting(index) {
      this.settingindex = index;
      if (index == 1) {
        api.myrobort().then((res) => {
          if (res.code == 0) {
            this.myrobortlist = res.data;
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else if (index == 5) {
        api.unlogin().then(() => {
          this.settingshow = false;
          this.islogin = false;
          localStorage.setItem("vb-token", "");
          this.getuserinfo();
          api.chathistory().then((res) => {
            if (res.code == 0) {
              this.chat_list = res.data;
              this.chat_list.forEach((element) => {
                element.select = false;
              });
              console.log(this.chat_list, 8989);
              this.chat_list[0].select = true;
              this.selecttype(
                this.chat_list[0].prompts.class,
                this.chat_list[0].prompts
              );

              this.$forceUpdate();
            }
          });
        });
      }
      this.$forceUpdate();
    },
    addrobortone() {
      if (this.huiliuflag) {
        ElMessage({
          message: "添加失败,请勿重复点击",
          type: "error",
        });
        return;
      }
      this.huiliuflag = true;
      if (
        this.rob_nickname == "" ||
        this.rob_sign == "" ||
        this.rob_tishi == ""
      ) {
        ElMessage({
          message: "请填写完信息、及头像",
          type: "error",
        });
        this.huiliuflag = false;
        return;
      }
      var that = this;
      let data = {};
      // data.avatar = "https://oss.onechatai.cn/avatar/avatar1.png"
      data.title = this.rob_nickname;
      data.desc = this.rob_sign;
      data.avatar = this.rob_img;
      data.prompt = this.rob_tishi;
      data.date = "刚刚";
      api.addrobort(data).then((res) => {
        if (res.code == 0) {
          let tempdata = {};
          tempdata.prompts = res.data;
          that.chat_list.forEach((element) => {
            element.select = false;
          });
          let temp = JSON.parse(JSON.stringify(tempdata));
          console.log(temp, 888);
          temp.select = true;
          console.log(temp)
          that.chat_list.unshift(temp);
          this.selecttype(1, temp.prompts);
          that.addrobortshow = false;
          that.huiliuflag = false;
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
          });
          if(res.msg == "未登录,请先登录"){
            that.$router.push({ name: "Vlogin" });
          }
          if(res.msg == "未绑定手机请先绑定"){
            that.bindphoneshow = true
          }
          that.huiliuflag = false;
        }
      });
    },
    addrobortinfo(item) {
      var that = this;
      console.log(item);
      that.addrobortobj = {
        obj: item,
        title: item.title,
        avatar: item.avatar,
        desc: item.desc,
        content: item.prompt,
      };
      that.$forceUpdate();
      that.addmyrobortshow = true;
    },
    selectrobortlist(item) {
      var that = this;
      let tempdata = {};
      tempdata.prompts = item;
      let index = -1;
      for (let j = 0; j < that.chat_list.length; j++) {
        that.chat_list[j].select = false;
        if (that.chat_list[j].prompts.id == item.id) {
          index = j;
          console.log(index, 47287382);
          break;
        }
      }
      console.log(that.chat_list, item);
      if (index != -1) {
        that.chat_list.splice(index, 1);
        console.log(that.chat_list, 8883);
      }
      let temp = JSON.parse(JSON.stringify(tempdata));
      temp.select = true;
      that.chat_list.unshift(temp);
      that.selecttype(temp.prompts.class, temp.prompts);
      that.settingshow = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = this.isImage(file.name);
      if (!isJPG) {
        this.$message.error("必须为图片格式");
      }
      return isJPG;
    },
    isImage(filename) {
      var pattern = /\.(jpg|jpeg|png|gif|bmp)$/i;
      return pattern.test(filename);
    },
    selectrobort() {
      let item = this.addrobortobj.obj;
      var that = this;
      let data = {};
      data.id = item.id;

      api.joinrobort(data).then((res) => {
        if (res.code == 0) {
          let tempdata = {};
          tempdata.prompts = item;
          let index = -1;
          console.log(item, 999, that.chat_list);
          for (let j = 0; j < that.chat_list.length; j++) {
            that.chat_list[j].select = false;
            if (that.chat_list[j].prompts.id == item.id) {
              index = j;
              break;
            }
          }
          if (index != -1) {
            that.chat_list.splice(index, 1);
          }
          let temp = JSON.parse(JSON.stringify(tempdata));
          temp.select = true;
          that.chat_list.unshift(temp);
          that.selecttype(temp.prompts.class, temp.prompts);
          that.searchshow = false;
          that.addmyrobortshow = false;
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    opensearch() {
      api.robotcategory().then((res) => {
        this.categorylist = res.data;
        this.categorylist.forEach((element) => {
          element.select = false;
        });
        this.categorylist[0].select = true;
        this.cateforyinfolist = this.categorylist[0].Prompts;
        this.searchshow = true;
      });
    },
    selectcate(item, index) {
      this.categorylist.forEach((element) => {
        element.select = false;
      });
      this.cateforyinfolist = this.categorylist[index].Prompts;
      item.select = true;
    },
    gotologin() {
      this.$router.push({ name: "Vlogin" });
    },
    selectchat(item) {
      if (this.tabchangeflag) {
        ElMessage({
          message: "操作太快啦，小忍跟不上，请重试一下~",
          type: "error",
        });
        return;
      }
      var that = this;
      that.tabchangeflag = true;
      // eslint-disable-next-line no-unused-vars
      var timerId = setTimeout(() => {
        that.tabchangeflag = false;
      }, 500);
      this.chat_list.forEach((element) => {
        element.select = false;
      });
      this.selecttype(item.prompts.class, item.prompts);
      item.select = true;
    },
    changeismenu() {
      this.$emit("changeismenu", false);
    },
    changeTheme(index) {
      if (index == 1) {
        if (!this.isdark) {
          this.isdark = true;
          localStorage.setItem("color", "dark");
          window.document.documentElement.setAttribute("data-theme", "dark");
          this.$emit("getDark", this.isdark);
        }
      } else {
        if (this.isdark) {
          return;
          // this.isdark = false
          // localStorage.setItem('color','light')
          // window.document.documentElement.setAttribute("data-theme", "light")
          // this.$emit('getDark',this.isdark)
        }
      }
    },
    changelanguage() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "zh";
        localStorage.setItem("lang", "zh");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
    jumpservicetext(index) {
      if (index == 1) {
        window.open("https://chat.onechatai.cn/service.html");
      } else {
        window.open("https://chat.onechatai.cn/privacynew.html");
      }
    },
    copyIframe() {
      navigator.clipboard.writeText(this.iframeData).then(() => {
        ElMessage({
          message: "复制成功",
          type: "success",
          grouping: true,
        });
      });
    },
      /* 手机号码 */
      validatePhoneNumber(str) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      return reg.test(str)
    },
    getCaptcha(){
      if(!this.validatePhoneNumber(this.phone)){
        ElMessage({
          message: '请输入正确手机号',
          type: 'error'
        })
        this.fangdou = false
        return
      }
      if(this.phone == ""){
        ElMessage({
            message: '请输入手机号',
            type: 'error'
          })
          this.fangdou = false
          return
      }
      let n = 60;
      this.codetext = n + "秒钟重新获取";
      const timer = setInterval(() => {
        if (n === 0) {
          clearInterval(timer);
          this.codetext = "获取验证码";
        } else {
          n--;
          this.codetext = n + "秒钟重新获取";
        }
      }, 1000);
      let data = {}
      data.phone = this.phone
      api.getcode(data).then((res)=>{
        this.fangdou = false
        if(res.code == 0){
          ElMessage({
              message: '发送成功',
              type: 'success'
          })
        }else{
          ElMessage({
            message: res.msg,
            type: 'error'
          })
        }
      });
      
    },
    bindphoneinfo(){
      var that = this
      let data = {}
      data.phone = this.phone
      data.smsCode = this.code
      api.bindmobile(data).then((res)=>{
        if(res.code == 0){
          that.code = ''
          that.phone = ''
           that.bindphoneshow = false
           ElMessage({
              message: '绑定成功',
              type: 'success'
          })
        }else{
          ElMessage({
            message: res.msg,
            type: 'error'
          })
        }
      });
    },
    sidersuccess(){
      console.log(12312)
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass-style/variable.scss";
.leftdiv {
  padding: 16px;
  width: 279px;
  @include left_ground_color();
  display: flex;
  height: calc(100% - 26px);
  flex-direction: column;
}
::v-deep .el-input__wrapper {
  background-color: #3a3b40 !important;
  border-radius: 8px;
  height: 34px;
  box-shadow: none !important;
}
::v-deep .topsearch .el-input {
  width: 208px !important;
}
::v-deep .topsearch .el-input__inner {
  cursor: pointer;
}

// ::v-deep .el-input__count-inner{
//   background:#3A3B40 !important;
// }

.topsearchbtn {
  background: #3a3b40;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 48px;
  height: 36px;
  font-weight: 800 !important;
  color: var(--fff);
  cursor: pointer;
}
.topsearchbtn:hover {
  background: #b1b3b8;
}

::v-deep .bottomsearch .el-input__wrapper {
  border: 1px solid #373c3f;
  background-color: #1c1d22 !important;
  border-radius: 8px;
  height: 32px;
  box-shadow: none !important;
}
::v-deep .bottomsearch .el-input {
  width: 208px !important;
}

.bottomsearchbtn {
  font-size: 24px;
  background: #3a3b40;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 48px;
  height: 36px;
  color: var(--fff);
  cursor: pointer;
}
.bottomsearchbtn:hover {
  background: #b1b3b8;
}

.bottomdarhthemecheckon {
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  background: #101819;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 126px;
  height: 32px;
  margin-top: 2px;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomdarktheme {
  margin-top: 2px;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  background: #252b2c;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 126px;
  height: 32px;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomlightthemecheckon {
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
  background: #101819;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 126px;
  height: 32px;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomlighttheme {
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
  background: #252b2c;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  width: 126px;
  height: 32px;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomsettingnew {
  font-size: 14px;
  background: #1c1d22;
  box-shadow: none !important;
  border-radius: 8px !important;
  border-color: transparent !important;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bottomsetting {
  font-size: 14px;
  background: #1c1d22;
  box-shadow: none !important;
  border-radius: 8px !important;
  border-color: transparent !important;
  width: 264px;
  height: 36px;
  color: var(--fff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bottomsetting:hover {
  background: var(--1f222a);
}
::v-deep .el-button--large {
  background-color: var(--primary);
  border-color: var(--primary);
}
.chat_listdiv {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  border-radius: 12px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  background-color: #3a3b406e;
}
.chat_listdiv:hover {
  background-color: rgba(19, 128, 255, 1);
}
#deleteicon {
  display: none;
}
.chat_listdiv:hover #deleteicon {
  display: block;
}

::v-deep .el-divider__text {
  background-color: #1c1d22;
  padding-left: 10px;
  padding-right: 10px;
}

::v-deep .el-input__wrapper {
  background-color: var(--1f222a);
}

::v-deep .el-textarea__inner {
  box-shadow: none !important;
  border: 1px solid #373c3f;
  background-color: #3a3b40 !important;
  color: white;
  border-radius: 8px;
}
::v-deep .el-input__inner {
  color: white;
}
div::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #9e9e9ebd;
}
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  // background: #9e9e9ebd;
}
::v-deep .settingclass .el-dialog__header {
  padding: 0px;
}
::v-deep .settingclass .el-dialog__body {
  padding: 0px !important;
  background: var(--181a20) !important;
}
.settingmenu {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  margin: 8px;
  border-radius: 10px;
}

::v-deep .el-popper {
  background: var(--1f222a);
  height: 200px !important;
  border: none;
  border-radius: 12px;
}


 
 
 .midshow{
  display:flex;align-items: center;justify-content: center;
 }
 
  
 .buttonbtn{
  cursor: pointer;
 }
 .verifycode{
  background-color: var(--primary);
    border-color: var(--primary);
  // width:142px;
  height:40px;
  border-radius:6px;
  font-size: 16px;
  letter-spacing:0.5px;
 }
 .loginbtn{

  width:360px;
  height:44px;
  border-radius:36px;font-size: 16px;margin-top:30px;letter-spacing:1px;
 }
</style>
