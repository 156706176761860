<template>
  <div v-clickOutside="handleHide" class="collect-text-visible" v-if="visilbe">
    <div class="header">
      <i className="back-icon iconfont icon-fanhui" @click="handleClose"></i>
      <span>设置生词高亮</span>
    </div>
    <div class="content">
      <p class="tips">收藏的单词将会在字幕中以高亮的方式突出显示</p>
      <div :class="`item ${curStyleIndex === item.value ? 'selected' : ''}`" v-for="item in StyleList" :key="item.value"
        @click="handleClick(item.value)">
        <div class="trancy-svg-icon icon-20"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
            width="24">
            <path
              d="m382-354 339-339q12-12 28.5-12t28.5 12q12 12 12 28.5T778-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z">
            </path>
          </svg> </div>
        <span :class="item.value">Hello</span><span>{{ item.item }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'

const store = useStore();
const emit = defineEmits(['changeVisible']);
const StyleList = [{ value: 'highLight', item: '高亮' }, { value: 'underline', item: '下划线' }, { value: 'default', item: '默认' }];
const visilbe = computed(() => store.state.videoSetting.collectTextPanelVisible);
const curStyleIndex = computed(() => store.state.subtitles.collectTextStyleIndex);

const handleClose = (parentShow = true) => {
  store.commit('videoSetting/updateCollectTextPanel', false);
  emit('changeVisible', parentShow);
}
const handleHide = (parentShow = true) => {
  store.commit('videoSetting/updateCollectTextPanel', false);
}
const handleClick = (styleIndex) => {
  store.commit('subtitles/updateCollectTextStyle', styleIndex);
}

</script>

<style lang="scss">
.collect-text-visible {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 340px;
  background: var(--181a20);
  border-radius: 4px;
  color: var(--fff05);
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 60px;
    color: var(--fff);
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid var(--2f3036);
    box-sizing: border-box;

    .back-icon {
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    padding: 16px 0;

    .tips {
      font-size: 12px;
      line-height: 1.6;
      color: var(--fff05);
      padding: 0px 16px 16px;
    }

    .item {
      display: flex;
      align-items: center;
      height: 72px;
      cursor: pointer;
      border-bottom: 1px solid var(--fff004);
      padding: 0px 16px;
      font-size: 14px;
      color: var(--fff05);
      padding: 0 20px;

      &:last-child {
        border: none;
      }

      &:hover {
        background: var(--fff004);
      }

      &.selected {
        .trancy-svg-icon {
          opacity: 1;
        }
      }

      .trancy-svg-icon {
        fill: var(--fff);
        margin-right: 5px;
        opacity: 0;
      }

      .highLight {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin-right: 20px;
        color: var(--fff);
        border: 1px solid var(--fff006);
        border-radius: 6px;
        padding: 0px 16px;
        color: green;
      }

      .underline {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin-right: 20px;
        color: var(--fff);
        border: 1px solid var(--fff006);
        border-radius: 6px;
        padding: 0px 16px;
        border-bottom: 2px solid green;
      }

      .default {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin-right: 20px;
        color: var(--fff);
        border: 1px solid var(--fff006);
        border-radius: 6px;
        padding: 0px 16px;
      }
    }
  }
}
</style>