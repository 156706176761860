export default [{
    startTime: 3,
    endTime: 8,
    text: 'Hello, hello, hello, hello, everyone. Hello. Hello.'
},
{
    startTime: 9,
    endTime: 10,
    text: 'Everyone.'
},
{
    startTime: 11,
    endTime: 18,
    text: 'Hello, hello, hello, everyone say Hello. Everyone.'
},
{
    startTime: 22,
    endTime: 22,
    text: 'Bob.'
},
{
    startTime: 24,
    endTime: 32,
    text: 'Walk, run and jump walk, walk.'
},
{
    startTime: 32,
    endTime: 39,
    text: 'Oh, hey, hello, I, hello, boys and girls. Oh,'
}, {
    startTime: 40,
    endTime: 46,
    text: 'Let\'s go for a walk here.Come on, stand up, stand up, stand up.'
},
{
    startTime: 46,
    endTime: 46,
    text: 'Yeah.'
}, {
    startTime: 48,
    endTime: 49,
    text: 'Now we can play a game.'
}, {
    startTime: 50,
    endTime: 57,
    text: 'Hey, ready. Show me how to walk, walk,.'
},
{
    startTime: 58,
    endTime: 61,
    text: 'Walk, walk here and stop. Stop.'
}, {
    startTime: 63,
    endTime: 71,
    text: 'Yeah, well, done, let\'s walk again.Come on, walk, walks up.'
}, {
    startTime: 73,
    endTime: 78,
    text: 'Stop grave one more time. Come on, let\'s walk.'
}, {
    startTime: 79,
    endTime: 84,
    text: 'Who walk, walk, walk, walk, walk, walk, walk, walk walks up.'
}, {
    startTime: 85,
    endTime: 90,
    text: 'Yeah, well, done, look on. Let\'s continue on my walk.'
}, {
    startTime: 90,
    endTime: 97,
    text: 'Walk, walk, walk, walk, walk, walk, walk, walk, walk stop, oh no.'
}, {
    startTime: 99,
    endTime: 101,
    text: 'Look, I can\'t walk there.'
}, {
    startTime: 103,
    endTime: 103,
    text: 'But I can.'
}, {
    startTime: 104,
    endTime: 108,
    text: 'Jump, hey, let\'s play the game again.Come on.'
}, {
    startTime: 109,
    endTime: 117,
    text: 'Show me how to jump here, come, let\'s jump jump, jump, jump jump jump jump and stop.'
}, {
    startTime: 118,
    endTime: 126,
    text: 'Stop, well, done, let\'s go again, ready jump, jump, jump, jump, jump jump jump and stop.'
}, {
    startTime: 144,
    endTime: 147,
    text: 'Stop, well done.'
}, {
    startTime: 148,
    endTime: 156,
    text: 'Let\'s go again, ready some big jumps, oh, jump, jump, jump, jump, jump jump.'
}, {
    startTime: 157,
    endTime: 165,
    text: 'Yes, stop, well, done, okay, time for me to jump over here here. We go jump.'
}, {
    startTime: 170,
    endTime: 176,
    text: 'Jump, jump, hoo hoo. I like jumping, but.'
}, {
    startTime: 179,
    endTime: 180,
    text: 'I\'m hot now.'
},
{
    startTime: 181,
    endTime: 186,
    text: 'Stable, hello, maggie, what are you doing I\'m jumping.'
},
{
    startTime: 188,
    endTime: 195,
    text: 'With the boys and girls love, say Hello, boys and girls, but Steve, you\'re late late.'
},
{
    startTime: 196,
    endTime: 200,
    text: 'Oh, no, that means we have to run.'
},
{
    startTime: 201,
    endTime: 207,
    text: 'Show me how to run, run,  run and stop. Stop.'
},
{
    startTime: 208,
    endTime: 215,
    text: 'Yeah, well, done, and again, let\'s go run, run, run, run.'
},
{
    startTime: 218,
    endTime: 222,
    text: 'Well done, yes, maggie, I\'m coming.'
}, {
    startTime: 223,
    endTime: 227,
    text: 'Time for me to run, see next time. Bye. Bye. Bye.'
}, {
    startTime: 233,
    endTime: 233,
    text: 'e'
}
]