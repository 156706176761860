import { createStore } from 'vuex'
import videoPlayer from './videoPlayer'
import subtitles from './subtitles';
import videoSetting from './videoSetting';

// 创建一个新的 store 实例
const store = createStore({
    modules: {
        videoPlayer,
        subtitles,
        videoSetting
    }
});
export default store