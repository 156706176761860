const DEFAULT_CONFIG = {
  hoverSearch: true,
  clickSpeech: true,
  aiSplit: false,
  hiddenControlBar: false,
}

const setting = {
  namespaced: true,
  state: {
    settings: localStorage.getItem('videoSettings') ? JSON.parse(localStorage.getItem('videoSettings')) : DEFAULT_CONFIG,
    keyboard: false,
    visible: false,
    subtitleTextPanelVisible: false,
    subtitleOpacity: 10,
    collectTextPanelVisible: false,
    chatRobotVisible: false
  },
  mutations: {
    updateSettingVisible(state, data) {
      state.visible = data;
    },
    updateSetting(state, data) {
      state.settings = {
        ...state,
        ...data
      }
      localStorage.setItem('videoSettings', JSON.stringify(state.settings));
    },
    updateKeyboard(state, status) {
      state.keyboard = status;
    },
    updateSubtitleTextPanel(state, status) {
      state.subtitleTextPanelVisible = status;
    },
    updateSubtitleOpacity(state, status) {
      state.subtitleOpacity = status;
    },
    updateCollectTextPanel(state, status) {
      state.collectTextPanelVisible = status;
    },
    updateChatRobot(state, status) {
      state.chatRobotVisible = status;
    }
  }
}

export default setting;