import Recorder from "js-audio-recorder";
import { ref } from 'vue';
const recorderConfig = {
    sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
    sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
    numChannels: 1, // 声道，支持 1 或 2， 默认是1
    // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
};
export function useRecorder() {
    // 口语模式--录音
    const recording = ref(false);
    const recorderPlaying = ref(false);
    let recorder = new Recorder(recorderConfig);
    const startRecord = () => {
        Recorder.getPermission().then(() => {
            recording.value = true;
            recorder.start();
        }, (error) => {
            console.log(`${error.name} : ${error.message}`);
        });
    };
    const stopRecord = () => {
        recording.value = false;
        recorder.stop();
    };
    const startReader = () => {
        recorder.play();
        recorderPlaying.value = true;
    };
    const stopReader = () => {
        recorder.stopPlay();
        recorderPlaying.value = false;
    }
    const toggleReader = () => {
        let fn = recorderPlaying.value ? stopReader : startReader;
        fn();
    };
    recorder.onplayend = () => {
        console.log("%c回调监听，音频已经完成播放", "color: #2196f3");
        recorderPlaying.value = false;
    };
    return {
        recording,
        recorderPlaying,
        startRecord,
        stopRecord,
        startReader,
        stopReader,
        toggleReader
    }
}