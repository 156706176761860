export const EVENTKeys = [
    {
        chinese: '退出',
        icon: 'ESC'
    },
    {
        chinese: '播放/暂停',
        icon: 'Space/k'
    },
    {
        chinese: '下一句',
        icon: '▶️'
    },
    {
        chinese: '加速播放',
        icon: 'S',
        keyCode: 83
    }, {
        chinese: '减速播放',
        icon: 'D',
        keyCode: 68
    }, {
        chinese: '上一句',
        icon: '◀️'
    },
    {
        chinese: '循环当前句',
        icon: 'R'
    },
    {
        chinese: '退后 2 秒',
        icon: '▲'
    },
    {
        chinese: '切换剧场/阅读模式',
        icon: 'M',
        keyCode: 77
    },
    {
        chinese: '练习模式',
        icon: 'P',
        keyCode: 80
    },
    {
        chinese: '查看快捷键',
        icon: '?',
        keyCode: 191
    },
    {
        chinese: '打开本页收藏',
        icon: 'C'
    },
    {
        chinese: '开启/关闭视频全屏',
        icon: ['⌥', 'W'],
        keyCode: 87
    }
]
export const EVENTKeys_SUBTITLES = [
    {
        chinese: '智能分句',
        icon: 'I'
    },
    {
        chinese: '放大字体',
        icon: '='
    },
    {
        chinese: '缩小字体',
        icon: '-'
    },
    {
        chinese: '增大字幕宽度',
        icon: ']'
    },
    {
        chinese: '减小字幕宽度',
        icon: '['
    }
]
export const EVENTKeys_PRATICE = [
    {
        chinese: '开启/关闭键盘声音',
        icon: ['⌥', 'X']
    },
    {
        chinese: '显示/隐藏视频',
        icon: ['⌥', 'V'],
        keyCode: 86
    },
    {
        chinese: '显示/隐藏翻译',
        icon: ['⌥', 'Z'],
        keyCode: 90
    },
    {
        chinese: '显示/隐藏提示',
        icon: ['⌥', 'C'],
        keyCode: 67
    },
    {
        chinese: '显示/隐藏单词',
        icon: ['⌥', 'A'],
        keyCode: 65
    }
]