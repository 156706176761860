const PLAY_STATE = {
    playing: 0,
    paused: 1,
    loading: 2,
    loaded: 3,
    ended: 4
};

const SUBTITLE_TYPES = [
    { label: '双语字幕', value: 1 },
    { label: '主字幕', value: 2 },
    { label: '翻译字幕', value: 3 },
    { label: '关闭字幕', value: 4 }
]
const PRACTICE_MODES = [
    { label: '听力模式', value: 1, icon: 'icon-tingli' },
    { label: '口语模式', value: 2, icon: 'icon-kouyu' },
    { label: '选词模式', value: 3, icon: 'icon-xuanci' },
    { label: '填空模式', value: 4, icon: 'icon-tiankong' },
    { label: '听写模式', value: 5, icon: 'icon-tingxie' },
    { label: '跟读模式', value: 6, icon: 'icon-tingxie' },
]

export const playbackRate = [0.5, 0.8, 1, 1.2, 1.5];

export const ModeType = {
    all: 0, // 整集循环播放
    single: 1, // 当前句子循环播放
    singleOnce: 2, // 当前句子只播放一次
    singleChange: 3 // 单句变速循环【0.5倍播放好 自动播放0.8倍，然后是1倍，然后1.2倍，然后是1.5倍，5个倍率联合在一起】
};

export default PLAY_STATE

export {
    SUBTITLE_TYPES,
    PRACTICE_MODES,
}