<template>
  <div></div>
</template>

<script>
import { ElMessage } from "@/components/MyMessage";
import api from "../api/api";
export default {
  name: "tokenLogin",
  data() {
    return {};
  },
  setup() {},
  created() {
    this.login();
  },
  methods: {
    login() {
      let data = {};
      data.token = this.$route.params.token;
      api.tokenLogin(data).then((res) => {
        if (res.code == 0) {
          localStorage.setItem("vb-token", res.data.token);
          this.$router.push({ name: "page" });
        } else {
          ElMessage({
            message: "登录失败",
            type: "error",
          });
          this.$router.push({ name: "Vlogin" });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
