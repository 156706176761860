<template>
    <el-tooltip class="box-item" effect="dark" :content="text" :show-arrow="false" placement="top">
        <slot />
    </el-tooltip>
</template>

<script>
export default {
    props: { text: String }
}
</script>

<style></style>