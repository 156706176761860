import { createI18n } from 'vue-i18n'; //引入vue-i18n组件
import zh from './zh';
import en from './en';
//注册i18n实例并引入语言文件
// 默认语言 - 
const default_lang = "en"

const i18n = createI18n({
    globalInjection: true,
    legacy: false, 
    locale: default_lang,
    messages: {
        zh,
        en,
      },
})

export default i18n