<template>
  <div v-clickOutside="handleHide" class="subtitle-text-visible" v-if="visilbe">
    <div class="header">
      <i className="back-icon iconfont icon-fanhui" @click="handleClose"></i>
      <span>设置字幕的样式</span>
    </div>
    <div class="content">
      <div class="item-fontsize-wrapper">
        <div v-for="size in sizes" :class="`item-fontsize ${parseInt(selectedSize) === size.value ? `selected` : ''}`"
          @click="handleCheckedSize(size.value)">{{ size.item
          }}
        </div>
      </div>
      <div class="item-fontsize-wrapper">
        <div v-for="sty in styles" :class="`item-fontsize ${parseInt(selectedWeight) === sty.value ? `selected` : ''}`"
          @click="handleCheckedWeight(sty.value)">{{
            sty.item
          }}
        </div>
      </div>
      <div class="item-subtitle-bg-wrapper">
        <div class="label">全屏字幕透明度</div>
        <el-input v-model="currentOpacity" placeholder="10" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'

const store = useStore();
const emit = defineEmits(['changeVisible']);

const visilbe = computed(() => store.state.videoSetting.subtitleTextPanelVisible);
const sizes = [{ value: 18, item: '偏小' }, { value: 24, item: '中等' }, { value: 32, item: '偏大' }];
const styles = [{ value: 400, item: '正常' }, { value: 600, item: '加粗' }];
const curStyle = computed(() => store.state.subtitles.style);
const selectedSize = ref(curStyle.value['font-size']);
const selectedWeight = ref(curStyle.value['font-weight']);
const currentOpacity = ref(10);

const handleClose = (parentShow = true) => {
  store.commit('videoSetting/updateSubtitleTextPanel', false);
  emit('changeVisible', parentShow);
}
const handleHide = (parentShow = true) => {
  store.commit('videoSetting/updateSubtitleTextPanel', false);
}
const handleCheckedSize = (size) => {
  selectedSize.value = size;
  store.commit('subtitles/updateStyle', { 'font-size': size + 'px' });
}
const handleCheckedWeight = (weight) => {
  selectedWeight.value = weight;
  store.commit('subtitles/updateStyle', { 'font-weight': weight });
}

const handleChangeOpacity = (value) => {
  store.commit('videoSetting/updateSubtitleOpacity', value)
}

</script>

<style lang="scss">
.subtitle-text-visible {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 340px;
  background: var(--181a20);
  border-radius: 4px;
  color: var(--fff05);
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 60px;
    color: var(--fff);
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid var(--2f3036);
    box-sizing: border-box;

    .back-icon {
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 16px 20px;

    .shortcuts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--2f3036);
      border-bottom: none;
      height: 36px;
      box-sizing: border-box;
      padding: 0 12px;

      &.bottom-border {
        border-bottom: 1px solid var(--2f3036);
      }

      .icon {
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        border: 1px solid var(--2f3036);
        box-sizing: border-box;

        &.padding2 {
          padding: 0 2px;
        }
      }
    }

    .split-box {
      height: 16px;
    }

    .practice-mode-title {
      line-height: 60px;
      font-weight: 500;
      color: #666
    }
  }
}

.single-select {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-fontsize-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  border: 1px solid var(--fff004);
  border-radius: 6px;
  overflow: hidden;
}

.item-fontsize-wrapper .item-fontsize {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 36px;
  color: var(--fff05);
  cursor: pointer;
  border-right: 1px solid var(--fff004);
  transition: all 0.2s ease 0s;
  overflow: hidden;

  &.selected {
    color: var(--fff);
    background: var(--fff004);
  }
}

.item-subtitle-bg-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 36px;
  border: 1px solid var(--fff004);
  border-radius: 6px;
  overflow: hidden;

  .label {
    font-size: 13px;
    color: var(--fff05);
    padding-left: 12px;
  }

  .el-input {
    appearance: none;
    height: 36px;
    border-top-width: initial;
    border-right-width: initial;
    border-bottom-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    width: 56px;
    text-align: center;
    font-size: 14px;
    color: var(--text-80);
    outline: none;
    background: none;
    border-style: none none none;
    border-image: initial;
    border-left: 1px solid var(--fff004);
    writing-mode: horizontal-tb !important;
    padding: 1px 2px;


    .el-input__wrapper {
      outline: none;
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}
</style>