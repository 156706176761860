import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang/index';
const productType = PRODUCT_TYPE;
// 此段代码写入main.js中
import axios from './utils/request.js'

import ElementPlus from 'element-plus'

import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'

import './sass-style/common.scss';
import './sass-style/theme.scss';

import 'element-plus/dist/index.css'
import store from './store';

import router from './router/index'
const app = createApp(App).use(i18n).use(VueHighlightJS).use(ElementPlus).use(axios).use(router).use(store);

const productInfo = productType === 'adult' ? {
    platform: 'pc',
    theme: 'dark'
} : {
    platform: 'mobile',
    theme: 'light'
}

window.productType = productType
document.body.setAttribute('data-theme', productInfo.theme)
document.body.setAttribute('data-platform', productInfo.platform)
window.onerror = function (message, source, lineno, colno, error) {
    console.error('捕获到全局错误:', message);
    console.log('错误来源文件:', source);
    console.log('行号:', lineno);
    console.log('列号:', colno);
    console.error('错误对象:', error);
    return true; // 返回true可以阻止浏览器默认的错误处理行为
};
app.config.errorHandler = function (err, vm, info) {
    // `err`是错误对象
    console.error('捕获到错误:', err);
    // `vm`是发生错误的Vue实例
    console.log('发生错误的组件实例:', vm);
    // `info`是一个包含错误来源信息的字符串
    console.log('错误来源:', info);
};
app.mount('#app');