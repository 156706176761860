<template>
  <div v-clickOutside="handleHide" class="quick-key-board" v-if="keyBoard">
    <div class="header">
      <i className="back-icon iconfont icon-fanhui" @click="handleClose"></i>
      <span>快捷键</span>
    </div>
    <div class="content">
      <ul>
        <li :class="`shortcuts ${index === EVENTKeys.length - 1 ? 'bottom-border' : ''}`" v-for="(item, index) in EVENTKeys">
          <span class="name">{{ item.chinese }}</span>
          <div style="display:flex;align-items:center;">
            <span class="icon">{{ isArray(item.icon) ? item.icon[0] : item.icon }}</span>
            <span v-if="isArray(item.icon) && item.icon[1]">+</span>
            <span class="icon" v-if="isArray(item.icon) && item.icon[1]">{{ item.icon[1] }}</span>
          </div>
        </li>
        <li class="split-box"></li>
        <li :class="`shortcuts ${index === EVENTKeys_SUBTITLES.length - 1 ? 'bottom-border' : ''}`" v-for="(item,index) in EVENTKeys_SUBTITLES"><span class="name">{{ item.chinese }}</span>
          <div style="display:flex;align-items:center;">
            <span class="icon">{{ isArray(item.icon) ? item.icon[0] : item.icon }}</span>
            <span v-if="isArray(item.icon) && item.icon[1]">+</span>
            <span class="icon" v-if="isArray(item.icon) && item.icon[1]">{{ item.icon[1] }}</span>
          </div>
        </li>
        <li class="practice-mode-title">练习模式</li>
        <li :class="`shortcuts ${index === EVENTKeys_PRATICE.length - 1 ? 'bottom-border' : ''}`" v-for="(item,index) in EVENTKeys_PRATICE"><span class="name">{{ item.chinese }}</span>
          <div style="display:flex;align-items:center;">
            <span class="icon">{{ isArray(item.icon) ? item.icon[0] : item.icon }}</span>
            <span v-if="isArray(item.icon) && item.icon[1]">+</span>
            <span class="icon" v-if="isArray(item.icon) && item.icon[1]">{{ item.icon[1] }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'
import { EVENTKeys, EVENTKeys_PRATICE, EVENTKeys_SUBTITLES } from '@/contants/eventkeys';

const store = useStore();
const emit = defineEmits(['changeVisible']);

const keyBoard = computed(() => store.state.videoSetting.keyboard);
const isArray = val => val instanceof Array;

const handleClose = (parentShow = true) => {
  store.commit('videoSetting/updateKeyboard', false);
  emit('changeVisible', parentShow);
}
const handleHide = (parentShow = true) => {
  store.commit('videoSetting/updateKeyboard', false);
}

</script>

<style lang="scss">
.quick-key-board {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 100px;
  width: 340px;
  background: var(--181a20);
  border-radius: 4px;
  color: var(--fff05);
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 60px;
    color: var(--fff);
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid var(--2f3036);
    box-sizing: border-box;

    .back-icon {
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 16px 20px;

    .shortcuts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--2f3036);
      border-bottom: none;
      height: 36px;
      box-sizing: border-box;
      padding: 0 12px;

      &.bottom-border {
        border-bottom: 1px solid var(--2f3036);
      }

      .icon {
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        border: 1px solid var(--2f3036);
        box-sizing: border-box;

        &.padding2 {
          padding: 0 2px;
        }
      }
    }

    .split-box {
      height: 16px;
    }

    .practice-mode-title {
      line-height: 60px;
      font-weight: 500;
      color: var(--fff05)
    }
  }
}
</style>