export default  {
    leftmenu: {
        new:'新的聊天',
        search:'搜索聊天...',
        talkhistroy:'对话历史',
        language:'修改中英文',
        setting:'设置',
        recycle:'回收站',
        light:'亮色',
        dark:'暗色',
    },
    midmenu:{
        remarkfont:'释放AI的力量',
        topfont:'开始新的聊天',

        chechklist1:'聊天记录搜索',
        chechklist2:'提示库',
        chechklist3:'集成',
        chechklist4:'在浏览器上本地运行',
        chechklist5:'无需登录',
        chechklist6:'使用您自己的API密钥',
        chechklist7:'无月费',
        chechklist8:'还有更多...',


        premium:'查看高级功能',
        remindone:'要开始使用，请在下面输入您的OpenAI API密钥',
        remindtwo:'您的API密钥储存在本地浏览器上，绝对不会发送到其他任何地方',

        interfencebtn:'输入接口密钥',
        remindthird:'从开放式AI仪表板获取API密钥',
        model:'型号：GPT-3.5',
        role:'选择角色',
        remindlib:'提示库',
        modelremindone:'模型：gpt-4',
        modelremindtwo:'系统指令：你是 ChatGPT，一个由 OpenAI 训练的大型语言模型。',
        inputcontent:'输入你要质询的内容...',
    }
}