<template>
    <div style="height:100%;overflow-y: hidden;display:flex;" >
      <Leftmenu @getDark="getDark" :ismenu="ismenu"  @changeismenu="changeismenu"/>
       <Midmenuchat :isdark="isdark" :ismenu="ismenu" @changeismenu="changeismenu" />
      
    </div>
    
  </template>
  
  <script>
  import Leftmenu from '../components/Leftmenu.vue'
  import Midmenuchat from '../components/Midmenuchat.vue'
  
  export default {
    name: 'App',
    components: {
      Leftmenu,
      Midmenuchat,
    },
    watch: {
     
      screenWidth: {
        handler: function (val) {
          console.log(val)
          if (val < 1600) {
            this.ismenu = false
          } else {
            this.ismenu = true
          }
        },
        immediate: true,
        deep:true
      },
    },
    data(){
      return {
        screenWidth: document.documentElement.clientWidth,
        isphone:false, //是否是手机
        isdark:false,//是否暗色主题
        ismenu:false,
      }
    },  
    setup(){
    
    },
    created() {
      this.screenWidth = document.body.clientWidth
      var local = localStorage.getItem("lang") == "zh"?"zh":"en"
      localStorage.setItem("lang",local)
      this.$i18n.locale  = local
      // 初始化主题色
      window.document.documentElement.setAttribute("data-theme", "dark")
      if (this._isMobile()) {
        this.isphone = true
      } else {
        this.isphone = false
      }
    },
    mounted() {
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
          return (() => {
            window.fullWidth = document.documentElement.clientWidth;
            that.screenWidth = window.fullWidth; // 宽
          })()
        };
      },
    methods:{
      changeismenu(flag){
        this.ismenu = flag
        console.log(this.ismenu)
      },
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
   
      getDark(isdark){
        this.isdark = isdark
      }
    }
  }
  </script>
  
  <style lang="scss">
  
  html,body,#app{ height: 100%; margin: 0; padding: 0; }
  </style>
  