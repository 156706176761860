<template>
  <div class="d-flex w-full h-full">
    <div class="w-full chat-content flex-1">
      <chat-mind-map
        ref="mindMapRef"
        class="container"
        :clickEdgeShowLabel="clickEdgeShowLabel"
        v-model="data"
        theme="dark"
        v-model:mode="mode"
        v-model:edges="edges"
        :mindmap="true"
        :sharp-corner="true"
        :default-edge-style="themeEdgeStyle[theme]"
        :default-node-style="themeConfig[theme]"
        :showEdgesChangeRoot="true"
        :scaleRatio="1.0"
        :allowChangeRoot="true"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { ChatMindMap } from "../libs/mindmap/mind";
import api from "../api/video";

const clickEdgeShowLabel = ref(false);
const mode = ref("edit");
const edges = ref([]);
const emit = defineEmits(["clickVideo"]);
const loading = ref(false);
const props = defineProps({ word: String });
const themeEdgeStyle = {
  default: {
    lineWidth: 2,
    stroke: "#8B738C",
  },
  dark: {
    lineWidth: 2,
    stroke: "#5a6ef0",
  },
};
const themeConfig = {
  white: {},
  dark: {
    btnStyle: {
      fill: "transparent",
      stroke: "transparent",
      lineWidth: 2,
    },
    btnTextStyle: {
      fill: "#fff",
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily:
        '"Microsoft YaHei", "PingFang SC", "Microsoft JhengHei", sans-serif',
      textBaseline: "middle",
      lineHeight: 16,
      maxCount: 16,
      maxWidth: 200,
    },
  },
};
const theme = ref("dark");
const mindMapRef = ref(null);
const preferTree = (node, depth = 0, index = 1) => {
  let newNode = {};
  console.log(`>>>>`, depth, index);
  const NodeId = "idIndex" + 10 * depth + index; // id的数据类型应该是string,否则g6会报错
  if (node.type === "text") {
    Object.assign(
      newNode,
      {
        id: NodeId,
        title: node.content,
      },
      depth === 0
        ? {
            rectStyle: {
              fill: "#000",
              lineWidth: 2,
            },
            textStyle: {
              fill: "#fff",
              fontSize: 18,
              lineHeight: 25,
            },
          }
        : null
    );
  } else if (node.type === "video") {
    Object.assign(newNode, {
      id: NodeId,
      nodeType: node.video_url ? "video" : "image",
      url: node.sentence_url,
      rectStyle: {
        width: 240,
        height: 132,
      },
      content: {
        video_url: node.video_url,
        video_id: node.video_id,
        startTime: node.start_time,
        endTime: node.end_time,
        primary: node.translate_sentence,
      },
    });
  }
  if (node.children) {
    newNode.children = [];
    node.children.forEach((node, _index) =>
      newNode.children.push(preferTree(node, depth + 1, index + "_" + _index))
    );
  }
  return newNode;
};
const getAllByWord = async (word) => {
  loading.value = true;
  const res = await api.getAllByWord(word);
  loading.value = false;
  return preferTree(res.data);
};
onMounted(() => {
  const _graph = mindMapRef.value.graph;
  _graph.on("click", (ev) => {
    const item = ev.item;
    const { nodeType, content } = item.getModel();
    if (nodeType === "video") {
      emit("clickVideo", content);
    }
  });
});
watch(
  () => props.word,
  () => {
    getAllByWord(props.word).then((res) => {
      const _graph = mindMapRef.value.graph;
      _graph.data([res]);
      _graph.render();
      _graph.zoom(0.8);
      _graph.fitCenter();
    });
  },
  { immediate: true }
);
</script>
<style lang="scss">
@import "../libs/mindmap/index.css";

.mind-toolbar {
  background-color: var(--1f222a);
}

.mu-mind-map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
