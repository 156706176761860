<template>
  <div class="read">
    <div class="lyric">
      <Subtitle v-if="subtitlesType !== 4" :showAll="true" />
      <p class="subtitle-closed" v-else>
        <i class="iconfont icon-danmu-" style="font-size: 22px"></i>字幕关闭
      </p>
    </div>
    <control-bar page="read" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import ControlBar from "@/components/VideoControlBar";
import Subtitle from "@/components/Subtitle";

const store = useStore();
const subtitlesType = computed(() => store.state.subtitles.subtitleType);
</script>

<style lang="scss">
.read {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  [data-platform="mobile"] & .lyric {
	width:100%;
	overflow: overlay;
	height: calc(100vh - 330px);
  }
  [data-platform="pc"] & .lyric {
    margin-left: 26%;
    width: 47vw;
    height: calc(100vh - 120px);
    padding: 28px 12px;
	overflow: overlay;
  }
  .subtitle-closed {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--fff);
    margin-top: 50%;
    transform: translateY(-50%);
  }
}
</style>
