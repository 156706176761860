<template>
    <div class="sentence">
        <template v-for="word in wordList">
            <div v-if="isArray(word)" class="group">
                <div v-for="item in word" class="item">
                    <slot :word="item"></slot>
                </div>
            </div>
            <div class="item" v-else>
                <slot :word="word"></slot>
            </div>
        </template>
    </div>
</template>
<script setup>
import { computed, watch, ref, defineProps } from "vue";
const props = defineProps({
    list: Array,
    highList: Array
});

const isArray = val => val instanceof Array


const wordList = computed(() => {
    const a = props.list || [];
    const b = props.highList;
    var c = [], index = 0;
    while (index < a.length) {
        var group = b.find(item => item.startsWith(a[index]));
        if (!group) {
            c.push(a[index]);
            index++;
            continue;
        } else {
            const groupArr = group.split(' ');
            for (let i = index; i < index + groupArr.length; i++) {
                if (a[i] !== groupArr[i - index]) {
                    c = c.concat(groupArr.slice(index, i));
                    break;
                } else if (i === index + groupArr.length - 1) {
                    c.push(groupArr);
                    index += groupArr.length;
                    break;
                }
            }
        }
    }
    return c;
});
</script>
<style scoped>
.sentence,
.group {
    display: flex;
    gap: 6px;
}

.group {
    background: #232E49;
    flex: none;
    height:33px;
    border-left: 4px solid #232E49;
}

.item {
    flex: none;
}
</style>