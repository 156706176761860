const toSeconds = (t) => {
  var s = 0.0;
  if (t) {
    var p = t.split(":");
    for (let i = 0; i < p.length; i++) {
      s = s * 60 + parseFloat(p[i].replace(",", "."));
    }
  }
  return s;
}

function getFileType(url) {
  const fileExtension = url.split('.').pop(); // 获取链接的文件后缀
  if (['lrc', 'srt', 'json'].includes(fileExtension)) {
    return fileExtension; // 返回 lrc 后缀
  } else {
    return 'unknown'; // 如果不是 lrc 或 srt 后缀，则返回未知类型
  }
}

// mock视频句子的id
const ids = [];

function createNewId() {
  return 0;
  let id = Math.floor(Math.random() * 1000);
  if (!ids.includes(id)) {
    ids.push(id);
    return id;
  } else {
    return createNewId();
  }
}

const OrderByStartTime = arr => {
  arr.sort((a, b) => a.startTime - b.startTime);
  return arr;
}
export const parseSubtitle = (url, content, type) => {
  const suffix = getFileType(url);
  let subtitleList = [];
  if (suffix === 'srt') {
    subtitleList = resloveSrt(content);
  } else if (suffix === 'lrc') {
    subtitleList = resloveLrc(content)
  } else if (suffix === 'json') {
    subtitleList = resloveJson(content, type)
  }
  return OrderByStartTime(subtitleList);
}

const resloveJson = ({ data: jsonDataList }, type) => {
  // 字幕对象
  return jsonDataList.map(jsonData => ({
    id: createNewId(),
    id: 0, // 初始化是id全部为0
    startTime: jsonData.startMillisecond / 1000,
    endTime: jsonData.endMillisecond / 1000,
    content: type === 'en' ? jsonData.englishText : jsonData.chinese,
    explain_id: 1,
    is_like: 0
  }))
}

const resolveLFSrt = (srt) => {
  var subtitles = [];
  var textSubtitles = srt.split('\n\n'); // 每条字幕的信息，包含了序号，时间，字幕内容
  for (var i = 0; i < textSubtitles.length; ++i) {
    var textSubtitle = textSubtitles[i].split('\n');

    if (textSubtitle.length >= 2) {
      // var sn = textSubtitle[0]; // 字幕的序号
      var startTime = toSeconds((textSubtitle[1].split(' --> ')[0]).trim()); // 字幕的开始时间
      var endTime = toSeconds((textSubtitle[1].split(' --> ')[1]).trim()); // 字幕的结束时间
      var content = textSubtitle[2]; // 字幕的内容

      // 字幕可能有多行
      if (textSubtitle.length > 2) {
        for (var j = 3; j < textSubtitle.length; j++) {
          content += '\n' + textSubtitle[j];
        }
      }

      // 字幕对象
      var subtitle = {
        id: createNewId(),
        id: 0, // 初始化是id全部为0
        startTime: startTime,
        endTime: endTime,
        content: content,
        explain_id: 1,
        is_like: 0
      };
      subtitles.push(subtitle);
    }
  }
  return subtitles;
}
const resolveCRLFSrt = (srtContent) => {

  const srtBlocks = srtContent.split("\r\n\r\n");
  const subtitles = [];

  for (let i = 0; i < srtBlocks.length; i++) {
    const block = srtBlocks[i];
    if (!block) continue;
    const lines = block.split("\r\n");
    const timeRange = lines[1].split(" --> ");
    const startTime = timeRange[0];
    const endTime = timeRange[1];
    const text = lines.slice(2).join("\r\n");

    subtitles.push({
      id: createNewId(),
      id: 0, // 初始化是id全部为0
      startTime: toSeconds(startTime),
      endTime: toSeconds(endTime),
      content: text,
      explain_id: 1,
      is_like: 0
    });
  }

  return subtitles;
}


export const resloveSrt = (srt) => {
  switch (true) {
    case srt.indexOf('\r\n') !== -1: return resolveCRLFSrt(srt);
    case srt.indexOf('\n') !== -1: return resolveLFSrt(srt);
  }
}

const _combineItem = (item, idx, arr, secondaryList) => {
  const { id, startTime, endTime, is_like, explain_id, content } = item;
  return {
    id,
    startTime,
    endTime,
    is_like,
    explain_id,
    primary: content,
    secondary: secondaryList[idx]?.content,
    wordList: content.match(/[\w'-]+|[^\w\s]/g),
    pre: arr[idx - 1],
    next: arr[idx + 1]
  }
};
export const combineSubtitles = (primaryList, secondaryList) => {
  let combinedContent = primaryList.map((item, idx, arr) => {
    return _combineItem(item, idx, arr, secondaryList);
  })
  return combinedContent;
}

export const resloveLrc = (fileContent) => {
  const lines = fileContent.trim().split('\n');
  const result = [];

  for (let i = 0; i < lines.length; i++) {
    // 使用正则表达式匹配时间标签和歌词内容
    const regex = /\[(\d{2}):(\d{2})\.(\d{2,3})\](.*)/;
    const match = lines[i].match(regex);

    if (match && match.index === 0) { // 确保匹配到的是开头的时间标签
      const startTime = toSeconds(`${match[1]}:${match[2]}.${match[3]}`)

      // 提取歌词内容
      const lyrics = match[4].trim();

      // 计算下一行的时间作为结束时间
      let endTime = startTime;
      if (i < lines.length - 1) {
        const nextMatch = lines[i + 1].match(regex);
        if (nextMatch) {
          endTime = toSeconds(`${nextMatch[1]}:${nextMatch[2]}.${nextMatch[3]}`)
        }
      }

      // 存储时间和歌词内容为对象
      result.push({
        id: createNewId(),
        startTime,
        endTime,
        content: lyrics,
        explain_id: 1,
        is_like: 0
      });
    }
  }

  return result;
}

export const isPunctuation = (char) => {
  // 定义中英文标点符号的正则表达式
  var punctuationRegex = /[\u2000-\u206F\u3000-\u303F\uFF00-\uFFEF\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u2010-\u2053]/;

  // 使用正则表达式进行匹配
  if (char.length === 1 && punctuationRegex.test(char)) {
    return true;
  } else {
    return false;
  }
}

