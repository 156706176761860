import { ElNotification as Message } from 'element-plus'
// 封装自定义的消息提示函数
export function ElMessage ({message, type = 'info', duration = 3000, position = 'bottom-left'}) {
  Message({
    message: message,
    type: type,
    duration: duration,
    customClass: 'custom-message-class',
    offset: 100, // 偏移量，调整消息框位置
    center: true,
    dangerouslyUseHTMLString: false,
    showClose: false,
    position,
    onClose: () => {
      console.log('消息框关闭');
    }
  });
}